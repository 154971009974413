import { Either } from "../../utils/CheckResponseSide";
import { Schedule } from "../entities";

export interface GetAllScheduleService {
  getAllScheduleImpl: (date: Date) => Promise<Either<Error, Schedule[]>> ;
}

export class GetAllScheduleInteractor {
  getAllScheduleService: GetAllScheduleService;

  constructor(getAllScheduleService: GetAllScheduleService) {
    this.getAllScheduleService = getAllScheduleService;
  }

  async getAllSchedule(date: Date): Promise<Either<Error, Schedule[]>> {
    const response = await this.getAllScheduleService.getAllScheduleImpl(date);
    return response;
  }
}