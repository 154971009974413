import { User } from "../../authentication/entities";
import { Either } from "../../utils/CheckResponseSide";

export interface GetAllUsersService {
  getAllUsersImpl: () => Promise<Either<Error, User[]>> ;
}

export class GetAllUsersInteractor {
  getAllUsersService: GetAllUsersService;

  constructor(getAllUsersService: GetAllUsersService) {
    this.getAllUsersService = getAllUsersService;
  }

  async getAllUsers(): Promise<Either<Error, User[]>> {
    const response = await this.getAllUsersService.getAllUsersImpl();
    return response;
  }
}