import { Either } from "../../utils/CheckResponseSide";
import { Credential, User } from "../entities";

export interface SignInService {
  signInWithCredential: (email: string, password: string) => Promise<Either<Error, User>> ;
}

export class SignInInteractor {
  signInService: SignInService;

  constructor(signInService: SignInService) {
    this.signInService = signInService;
  }

  async signIn(email: string, password: string): Promise<Either<Error, User>> {
    const response = await this.signInService.signInWithCredential(email, password);
    return response;
  }
}