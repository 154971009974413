import { applyMiddleware, combineReducers, compose } from "redux";
import {legacy_createStore as createStore} from 'redux'
import createSagaMiddleware from "redux-saga";
import {
  allScheduleReducer,
  allUsersReducer,
  loadingReducer,
  loadingSigOutReducer,
  rootSaga,
  scheduleReducer,
  userReducer,
  purchaseReducer,
  pricesReducer,
  metricsReduce,
  metricsByIdReduce,
} from "../adapters";
import { messageReduce } from "../adapters/redux/actionMessage";

const rootReducer = {
  user: userReducer,
  userSchedule: scheduleReducer,
  loading: loadingReducer,
  allSchedule: allScheduleReducer,
  loadingSignOut: loadingSigOutReducer,
  allUsers: allUsersReducer,
  allPurchase: purchaseReducer,
  allPrices: pricesReducer,
  actionMessage: messageReduce,
  allMetrics: metricsReduce,
  metricsById: metricsByIdReduce
};

export const configureStore = (preloadState: any) => {
  const middleware = [];
  const sagaMiddleware = createSagaMiddleware();

  middleware.push(sagaMiddleware);
  if (process.env.NODE_ENV !== "production") {
    //middleware.push(createLogger());
  }

  const middlewareEnhancer = applyMiddleware(...middleware);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers) as typeof createStore;

  const store = createStore(
    combineReducers(rootReducer),
    preloadState,
    composedEnhancers
  );

  sagaMiddleware.run(rootSaga);
  return store;
};