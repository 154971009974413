import { Either, left, right } from '../../utils/CheckResponseSide';
import { GetAllScheduleService } from '../useCases';
import { Schedule } from '../entities';
import { GetUserSchedulesService } from '../useCases/getUserSchedulesInteractor';
import { UpdateUserSchedulesService } from '../useCases/updateUserScheduleInteractor';
import { GetAllUsersService } from '../useCases/getAllUsersInteractor';
import { UpdateUserProfileInfoService } from '../useCases/UpdateUserProfileInfoInteractor';

const GRAPHQL_ENDPOINT = "https://3ue6aegggvedndfjtxez3hjnlq.appsync-api.us-east-1.amazonaws.com/graphql";
const GRAPHQL_API_KEY = "da2-va6z7bp4p5cp7k5ambqjzxmngi";

const response: any = {};

type UserSchedueleData = {
  id: number;
  table: string[];
  hours: string[];
  user: string;
};

export class ScheduleServices implements 
    GetAllScheduleService,
    GetUserSchedulesService,
    UpdateUserSchedulesService,
    GetAllUsersService,
    UpdateUserProfileInfoService
  {
    

  async getUserSchedulesImpl(id: string): Promise<any> {

    const query = /* GraphQL */ `
      query MyQuery {
        getUser(id: "${id}") {
          email
          firstName
          lastName
          active
          schedule
          phoneNumber
          activeDays
          limitDate
          userType
          startDate
        }
      }
    `;

    const options = {
      method: "POST",
      headers: {
        "x-api-key": GRAPHQL_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query: query }),
    };
    try {
      const res = await fetch(GRAPHQL_ENDPOINT, options);
      response.data = await res.json();
      
      if (response.data.errors) response.statusCode = 400;
      const userCasted = {
        _firstName: response.data.data.getUser.firstName,
        _lastName: response.data.data.getUser.lastName,
        _userStatus: "activeUser",
        _email: response.data.data.getUser.email,
        _schedule: response.data.data.getUser.schedule,
        _active: response.data.data.getUser.active,
        _activeDays: response.data.data.getUser.activeDays,
        _limitDate: response.data.data.getUser.limitDate,
        _userType: response.data.data.getUser.userType,
        _startDate: response.data.data.getUser.startDate,
        _phoneNumber: response.data.data.getUser.phoneNumber

      };
      return right(userCasted);
    } catch (error) {
      console.log(error, "error")
      response.statusCode = 400;
      response.body = {
        errors: [
          {
            message: error,
            stack: error,
          },
        ],
      };
    }
  }

  async getAllScheduleImpl(date: Date): Promise<any> {

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 because January is 0
    const day = String(today.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    /**
     * query MyQuery($nextToken: String) {
      listSchedules(limit: 10000, nextToken: $nextToken) {
     */
    //listSchedules(filter: {date: {ge: "${formattedDate}"}}) {
    const query = /* GraphQL */ `
    query MyQuery {
      listSchedules(limit: 100000, filter: {date: {ge: "${formattedDate}"}}) {
        nextToken
        items {
          id
          date
          cluster0506
          cluster0607
          cluster0708
          cluster0809
          cluster1617
          cluster1718
          cluster1819
          cluster1920
          cluster2021
        }
      }
    }
    
    `;

    const options = {
      method: "POST",
      headers: {
        "x-api-key": GRAPHQL_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query: query }),
    };
    try {
      const res = await fetch(GRAPHQL_ENDPOINT, options);
      const reader = await res.body?.getReader().read();
      const result = new TextDecoder().decode(reader?.value);
      
      return right(JSON.parse(result).data);
    } catch (error) {
      console.log(error, "error")
      response.statusCode = 400;
      response.body = {
        errors: [
          {
            message: error,
            stack: error,
          },
        ],
      };
    }
  }


  async getAllUsersImpl(): Promise<any> {

    const query = /* GraphQL */ `
    query MyQuery($nextToken: String) {
      listUsers(limit: 10000, nextToken: $nextToken) {
        nextToken
        items {
          firstName
          email
          lastName
          phoneNumber
          activeDays
          limitDate
          active
          userType
          startDate
          schedule
          createdAt
        }
      }
    }
    
    `;

    const options = {
      method: "POST",
      headers: {
        "x-api-key": GRAPHQL_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query: query }),
    };
    try {
      const res = await fetch(GRAPHQL_ENDPOINT, options);

      if (!res.body) {
          console.error("No response body available");
          return;
      }

      const reader = res.body.getReader();
      let chunks = [];
      let totalLength = 0;

      while (true) {
          const { done, value } = await reader.read();
          if (done) {
              break;
          }
          chunks.push(value);
          totalLength += value.length;
      }

      const concatenated = new Uint8Array(totalLength);
      let offset = 0;
      for (let chunk of chunks) {
          concatenated.set(chunk, offset);
          offset += chunk.length;
      }

      const result = new TextDecoder().decode(concatenated);

      const usersResult = JSON.parse(result).data.listUsers.items;

      usersResult.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

      return right(usersResult);
    } catch (error) {

      console.log(error, "error");
      response.statusCode = 400;
      response.body = {
        errors: [
          {
            message: error,
            stack: error,
          },
        ],
      };
      left(response);
    }
  }

  async updateUserScheduleImpl(data: UserSchedueleData[]): Promise<any> {

    const inputData = JSON.stringify(data);
    const variables = {
      data: inputData
    };
    
    const query = /* GraphQL */ `
    query UpdateUserSchedule($data: String!) {
      updateUserSchedule(data: $data)
    }
    `;

    const options = {
      method: "POST",
      headers: {
        "x-api-key": GRAPHQL_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query, variables }),
    };
    try {
      const response = await fetch(GRAPHQL_ENDPOINT, options);
      let result = await response.json();

      return right({
        status: result.data.updateUserSchedule.includes("200") ? "200": "500",
        action: 'scheduleUpdated'
      })
      
    } catch (error) {
      console.log(error, "error")
    }
  }

  async updateUserInfoImpl(
      id: string,
      active: boolean,
      activeDays: number,
      limitDate: string,
      userType: string,
      startDate: string
    ): Promise<any> {

      const query = /* GraphQL */ `
          mutation UpdateUserInfo($id: ID!, $active: Boolean, $activeDays: Int, $limitDate: AWSDate, $userType: String, $startDate: AWSDate) {
            updateUser(input: { id: $id, active: $active, activeDays: $activeDays, limitDate: $limitDate, userType: $userType, startDate: $startDate }) {
                id
                active
                activeDays
                limitDate
                userType
                startDate
            }
          }
      `;

      const variables = {
          id,
          active,
          activeDays,
          limitDate,
          userType,
          startDate
      };

      const options = {
          method: "POST",
          headers: {
              "x-api-key": GRAPHQL_API_KEY,
              "Content-Type": "application/json",
          },
          body: JSON.stringify({ query, variables }),
      };

      try {
        const res = await fetch(GRAPHQL_ENDPOINT, options);
        await res.json();
      } catch (error) {
        console.log(error, "error");
      }

  }



  async updateUserProfileInfoImpl(
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
  ) {
    const query = /* GraphQL */ `
      mutation UpdateUserInfo(
        $id: ID!
        $firstName: String
        $lastName: String
        $email: String
        $phoneNumber: String
      ) {
        updateUser(
          input: {
            id: $id
            firstName: $firstName
            lastName: $lastName
            email: $email
            phoneNumber: $phoneNumber
          }
          condition: { email: { eq: $email } }
        ) {
          id,
          firstName,
          lastName,
          active,
          activeDays,
          limitDate,
          userType,
          startDate,
          phoneNumber
        }
      }
    `;
  
    const variables = {
      id,
      firstName,
      lastName,
      phoneNumber,
      email
    };
  
    const options = {
      method: "POST",
      headers: {
        "x-api-key": GRAPHQL_API_KEY,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ query, variables })
    };
  
    try {
      const res = await fetch(GRAPHQL_ENDPOINT, options);
      const data = await res.json();
      return data;
    } catch (error) {
      console.error(error, "error");
      throw new Error("Failed to update user profile");
    }
  }
  

}



