import { User } from "../../authentication/entities";
import { Either } from "../../utils/CheckResponseSide";
import { Purchase } from "../entities/shopping";

export interface CreatePurchaseService {
  createPurchaseImpl: (data: any) => Promise<Either<Error, Purchase[]>> ;
}

export class CreatePurchaseInteractor {
  createPurchaseService: CreatePurchaseService;

  constructor(createPurchaseService: CreatePurchaseService) {
    this.createPurchaseService = createPurchaseService;
  }

  async createPurchase(data: any): Promise<Either<Error, Purchase[]>> {
  
    const response = await this.createPurchaseService.createPurchaseImpl(data);
    return response;
  }
}