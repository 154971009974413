import { Prices } from "../../../shopping/entities/prices";
import { Purchase } from "../../../shopping/entities/shopping";
import { StateType, initialState } from "./state";

const UPDATE_PURCHASE = "purchase/update";
const UPDATE_PRICES = "prices/update";

type StateSlicePurchase = StateType["allPurchase"];
type StateSlicePrices = StateType["allPrices"];

export interface UpdatePurchaseActionType {
  type: typeof UPDATE_PURCHASE,
  purchase: Purchase[] | null,
}

export interface UpdatePricesActionType {
  type: typeof UPDATE_PRICES,
  prices: Prices[] | null,
}

export const allPurchaseSelector = (state: StateType): StateSlicePurchase => state.allPurchase;

export const updatePurchaseAction = (purchase: Purchase[] | null): UpdatePurchaseActionType => ({
  type: UPDATE_PURCHASE,
  purchase,
});

export const allPricesSelector = (state: StateType): StateSlicePrices => state.allPrices;

export const updatePricesAction = (prices: Prices[] | null): UpdatePricesActionType => ({
  type: UPDATE_PRICES,
  prices,
});

const updatePurchaseHandler = (
  state: StateSlicePurchase,
  action: UpdatePurchaseActionType
): StateSlicePurchase => {
  return action.purchase;
};

const updatePricesHandler = (
  state: StateSlicePrices,
  action: UpdatePricesActionType
): StateSlicePrices => {
  return action.prices;
};

export const purchaseReducer = (
  state: StateSlicePurchase = initialState.allPurchase,
  action: UpdatePurchaseActionType
): StateSlicePurchase => {
  switch (action.type) {
    case UPDATE_PURCHASE:
      return updatePurchaseHandler(state, action);
    default:
      return state;
  }
};

export const pricesReducer = (
  state: StateSlicePrices = initialState.allPrices,
  action: UpdatePricesActionType
): StateSlicePrices => {
  switch (action.type) {
    case UPDATE_PRICES:
      return updatePricesHandler(state, action);
    default:
      return state;
  }
};
