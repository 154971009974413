import { Either } from "../../utils/CheckResponseSide";
import { Credential, User } from "../entities";

export interface GetCurrentUserService {
  getActualCurrentUser: () => Promise<Either<Error, User>>  ;
}

export class GetCurrentUserInteractor {
  getCurrentUserService: GetCurrentUserService;

  constructor(getCurrentUserService: GetCurrentUserService) {
    this.getCurrentUserService = getCurrentUserService;
  }

  async getCurrentActiveUser(): Promise<Either<Error, User>>  {
    const response = await this.getCurrentUserService.getActualCurrentUser();
    return response;
  }
}