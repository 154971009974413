import { Either } from "../../utils/CheckResponseSide";
import { User } from "../entities";

export interface ResendEmailCodeService {
  resendEmailCode: (email: string) => Promise<Either<Error, User>> ;
}

export class ReSendEmailCodeInteractor {
  reSendEmailService: ResendEmailCodeService;

  constructor(reSendEmailService: ResendEmailCodeService) {
    this.reSendEmailService = reSendEmailService;
  }

  async resendCode(email: string): Promise<Either<Error, User>> {
    const response = await this.reSendEmailService.resendEmailCode(email);
    return response;
  }
}