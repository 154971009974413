import { StateType, initialState } from "./state";

const LOADING = "schedule/loading";
const LOADING_SIGN_OUT = "schedule/loading_sign_out";

type StateSlice = StateType["loading"];
type StateSliceSignOut = StateType["loadingSignOut"];

export interface UpdateLoadingActionType {
  type: string,
  loading: boolean,
}

export interface UpdateLoadingSignOutActionType {
  type: string,
  loadingSignOut: boolean,
}

export const loadingSelector = (state: StateType): StateSlice => state.loading;
export const updateLoadingAction = (loading: boolean): UpdateLoadingActionType => ({
  type: LOADING,
  loading
});

export const loadingSignOutSelector = (state: StateType): StateSliceSignOut => state.loadingSignOut;
export const updateLoadingSignOutAction = (loadingSignOut: boolean): UpdateLoadingSignOutActionType => ({
  type: LOADING_SIGN_OUT,
  loadingSignOut
});

const updateHandler = (
  state: StateSlice,
  action: UpdateLoadingActionType
): StateType["loading"] => {
  return action.loading;
};

const updateHandlerSignOut = (
  state: StateSlice,
  action: UpdateLoadingSignOutActionType
): StateType["loadingSignOut"] => {
  return action.loadingSignOut;
};

export const loadingReducer = (
  state: StateSlice = initialState.loading,
  action: UpdateLoadingActionType,
): StateSlice => {
  switch (action.type) {
    case LOADING:
      return updateHandler(state, action);
    default:
      return state;
  }
};

export const loadingSigOutReducer = (
  state: StateSliceSignOut = initialState.loadingSignOut,
  action: UpdateLoadingSignOutActionType,
): StateSliceSignOut => {
  switch (action.type) {
    case LOADING_SIGN_OUT:
      return updateHandlerSignOut(state, action);
    default:
      return state;
  }
};