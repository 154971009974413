import { User } from "../../authentication/entities";
import { Either } from "../../utils/CheckResponseSide";
import { Purchase } from "../entities/shopping";

export interface GetAllPurchaseService {
  getAllPurchaseImpl: () => Promise<Either<Error, Purchase[]>> ;
}

export class GetAllPurchaseInteractor {
  getAllPurchaseService: GetAllPurchaseService;

  constructor(getAllPurchaseService: GetAllPurchaseService) {
    this.getAllPurchaseService = getAllPurchaseService;
  }

  async getAllPurchase(): Promise<Either<Error, Purchase[]>> {
    const response = await this.getAllPurchaseService.getAllPurchaseImpl();
    return response;
  }
}