import React, { useState, ChangeEvent, FormEvent } from "react";
import HeaderComponent from "./Header/HeaderComponent";
import { Box, CircularProgress } from "@mui/material";
import { Props } from "./AppPresenter";
import { useNavigate } from "react-router-dom";

interface PropsType {
  props: Props;
  onClick: (email: string, code: string) => void;
  onResendCode: (email: string) => void;
}

const SendRecoveryConfirmationComponent: React.FC<PropsType> = ({ props, onClick, onResendCode }) => {

  const navigate = useNavigate();

  const [email, setEmail] = useState("");

  const [loading, setLoading] = useState(props.loading);

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await onResendCode(email);
    } catch (err) {
      console.log(err, 'manage error');
    }
  };

  React.useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  React.useEffect(() => {
    console.log(props.user);
    if (props.user?._userStatus === "userNeedRecovery") {
      navigate('/reset');
    }
  }, [props.user?._userStatus]);

  return (
    <>
      <HeaderComponent />
      <div className="signout_container">
        <div className="signout_sub_container">
        <p className="signout_title">Ingresa el email que queires recuperar</p>
        <form onSubmit={handleSubmit}>
          <div className="signout_input_container">
            <div className="signout_title_label">
                Email
            </div>
            <input
              className="signout_input_item"
              type="text"
              value={email}
              placeholder="email"
              onChange={handleChangeEmail}
            />
          </div>
          <div className="signin_registration_container">
            <button className="signout_button_item" type="submit">
            {
                !loading ? "Enviar código" : 
                <Box sx={{ display: 'flex'}}>
                  <CircularProgress size="16px" style={{color: "gray"}} />
                </Box>
              }
            </button>
          </div>
          
        </form>
        
        </div>
      </div>
    </>
  );
};

export default SendRecoveryConfirmationComponent;
