import React, { useState, ChangeEvent, FormEvent } from "react";
import HeaderComponent from "./Header/HeaderComponent";
import { Box, CircularProgress } from "@mui/material";
import { Props } from "./AppPresenter";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useNavigate } from "react-router-dom";

interface PropsType {
  props: Props;
  onClick: (email: string, code: string) => void;
  onResendCode: (email: string, code: string, password: string) => void;
}

const RecoveryConfirmationComponent: React.FC<PropsType> = ({ props, onClick, onResendCode }) => {

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(props.loading);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangeCode = (event: ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  };

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
      try {
        await onResendCode(email, code, password);
      } catch (error) {
        console.log(error, 'manage error');
        
      }
  };

  React.useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  React.useEffect(() => {
    console.log(props.user);
    if (props.user?._userStatus === "userRecoveryPasswordSent") {
      navigate('/signin');
    }
  }, [props.user?._userStatus]);

  return (
    <>
      <HeaderComponent />
      <div className="signout_container">
        <div className="signout_sub_container">
        <p className="signout_title">Ingresa el código de confirmación</p>
        <p className="signout_title subtitle">El código podría tomar un tiempo en llegar</p>
        <form onSubmit={handleSubmit}>
          <div className="signout_input_container">
            <div className="signout_title_label">
                Email
            </div>
            <input
              className="signout_input_item"
              type="text"
              value={email}
              placeholder="email"
              onChange={handleChangeEmail}
            />
          </div>
          <div className="signout_input_container">
            <div className="signout_title_label">
                Code
            </div>
            <input
              className="signout_input_item"
              type="text"
              value={code}
              placeholder="code"
              onChange={handleChangeCode}
            />
          </div>
          <div className="signout_input_container">
          <div className="signout_title_label">
              Password
            </div>
            <input
              className="signout_input_item password"
              type={passwordVisible ? 'text' : 'password'}
              name="password"
              value={password}
              placeholder="contraseña"
              onChange={handleChangePassword}
            />
            <div
              onClick={togglePasswordVisibility}
              className="password_toggle_btn"
            >
              {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </div>
          </div>
          <div className="signin_registration_container">
            <button className="signout_button_item" type="submit">
            {
                !loading ? "Enviar" : 
                <Box sx={{ display: 'flex'}}>
                  <CircularProgress size="16px" style={{color: "gray"}} />
                </Box>
              }
            </button>
          </div>
          
        </form>
        
        </div>
      </div>
    </>
  );
};

export default RecoveryConfirmationComponent;
