import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { checkingUser } from '../utils/checkingUser';

const withAuthAndVisibility = <P extends object>(
    WrappedComponent: React.ComponentType<P>) => {
    return (props: any) => {
      const {...restProps } = props;

      const location = useLocation();

      const validUser = checkingUser(props.props.user);

      if ((location.pathname === '/purchase' || location.pathname === '/dashboard') && props.props.user._userType.toString() !== "1") {
        return <Navigate to="/" />;
      }

      if (!validUser) {
        return <Navigate to="/invalid" />;
      }

      return <WrappedComponent {...(restProps as P)} />;

      
    };
};

export default withAuthAndVisibility;
