import { StateType, initialState } from "./state";

const UPDATE_MESSAGE = "actionMessage/update";

type StateSliceMessage = StateType["actionMessage"];

export interface UpdateMessageActionType { 
  type: typeof UPDATE_MESSAGE,
  message: String | null,
}

export const messageSelector = (state: StateType): StateSliceMessage => state.actionMessage;

export const updateMessageAction = (message: String | null): UpdateMessageActionType => ({
  type: UPDATE_MESSAGE,
  message,
});

const updateMessageHandler = (
  state: StateSliceMessage,
  action: UpdateMessageActionType
): StateSliceMessage => {
  return action.message;
};


export const messageReduce = (
  state: StateSliceMessage = initialState.actionMessage,
  action: UpdateMessageActionType
): StateSliceMessage => {
  switch (action.type) {
    case UPDATE_MESSAGE:
      return updateMessageHandler(state, action);
    default:
      return state;
  }
};
