import React, { useState } from "react";

import './DashBoardPurchaseComponentStyles.css';
import { Props } from "../AppPresenter";
import { Box, CircularProgress } from "@mui/material";
import withAuthAndVisibility from "../UserAuth";

interface PropsType {
    props: Props,
    updateUser: (id: string, active: boolean, activeDays: number, limitDate: string, userType: string, startDate: string) => void;
    updatePrices: (
        id: string,
        monthly: string,
        valley: string,
        quarterly: string,
        semester: string,
        ticket01: string,
        ticket04: string,
        ticket08: string,
        ticket12: string,
        ticket16: string,
    ) => void;
  }

const DashBoardPurchaseComponent: React.FC<PropsType>  = ({ props, updateUser, updatePrices }) => {

    const [loading, setLoading] = useState(props.loading);
    const [loadingPrices, setLoadingPrices] = useState(props.loadingSignOut);
    const [ buttonClicked, setButtonClicked ] = useState(""); 

    let activeUsers: any = {};
    let activeDays: any = {};
    let activePlan: any = {};
    let limitDates: any = {};
    let userTypes: any = {};
    let startDates: any = {};    

    props.allPurchase?.map((user: any) => {
        
        activeUsers = {
            ...activeUsers,
            [user.email]: user.active
        };
        activePlan = {
            ...activePlan,
            [user.email]: user.plan
        };
        activeDays = {
            ...activeDays,
            [user.email]: user.activeDays
        };
        limitDates = {
            ...limitDates,
            [user.email]: user.limitDate
        };
        userTypes = {
            ...userTypes,
            [user.email]: user.userType
        };
        startDates = {
            ...startDates,
            [user.email]: user.startDate
        };
    })

    const [activeDaysUsersState, setActiveDaysUsersState] = useState(activeDays);
    const [activePlanState, setActivePlanState] = useState(activePlan);
    const [limiteDateUsersState, setLimiteDateUsersState] = useState(limitDates);
    const [startDateUsersState, setStartDateUsersState] = useState(startDates);

    let id = (props.allPrices?.[0] as any)?.id;
    let monthly = (props.allPrices?.[0] as any)?.monthly;
    let quarterly = (props.allPrices?.[0] as any)?.quarterly;
    let semester = (props.allPrices?.[0] as any)?.semester;
    let ticket01 = (props.allPrices?.[0] as any)?.ticket01;
    let ticket04 = (props.allPrices?.[0] as any)?.ticket04;
    let ticket08 = (props.allPrices?.[0] as any)?.ticket08;
    let ticket12 = (props.allPrices?.[0] as any)?.ticket12;
    let ticket16 = (props.allPrices?.[0] as any)?.ticket16;
    let valley = (props.allPrices?.[0] as any)?.valley;

    const [monthlyPrice, setMonthlyPriceState] = useState(monthly);
    const [valleyPrice, setValleyPrice] = useState(valley);
    const [quarterlyPrice, setQuarterlyPriceState] = useState(quarterly);
    const [semesterPrice, setSemesterPriceState] = useState(semester);
    const [ticket01Price, setTicket01PriceState] = useState(ticket01);
    const [ticket04Price, setTicket04PriceState] = useState(ticket04);
    const [ticket08Price, setTicket08StateState] = useState(ticket08);
    const [ticket12Price, setTicket12PriceState] = useState(ticket12);
    const [ticket16Price, setTicket16PriceState] = useState(ticket16);

    const handleActiveMonthlyPrice = (price: string) => {
        setMonthlyPriceState(price)
    }
    const handleActiveValleyPrice = (price: string) => {
        setValleyPrice(price)
    }
    const handleActiveQuarterlyPrice = (price: string) => {
        setQuarterlyPriceState(price)
    }
    const handleActiveSemesterPrice = (price: string) => {
        setSemesterPriceState(price)
    }
    const handleActiveTicket01Price = (price: string) => {
        setTicket01PriceState(price)
    }
    const handleActiveTicket04Price = (price: string) => {
        setTicket04PriceState(price)
    }
    const handleActiveTicket08Price = (price: string) => {
        setTicket08StateState(price)
    }
    const handleActiveTicket12Price = (price: string) => {
        setTicket12PriceState(price)
    }
    const handleActiveTicket16Price = (price: string) => {
        setTicket16PriceState(price)
    }

    const handleActiveDaysUser = (user: string, days: number) => {
        
        setActiveDaysUsersState({
            ...activeDaysUsersState,
            [user]: days
        })
    }

    const handleActivePlan = (user: string, plan: number) => {
        
        setActivePlanState({
            ...activeDaysUsersState,
            [user]: plan
        })
    }

    const handleActiveLimitDateUser = (user: string, date: string) => {
        const dateObject = new Date(`${date}T00:00:00-05:00`);
        
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');

        setLimiteDateUsersState({
            ...limiteDateUsersState,
            [user]: `${year}-${month}-${day}`
        })
    }

    const handleActiveStartDateUser = (user: string, date: string) => {
        const dateObject = new Date(`${date}T00:00:00-05:00`);
        
        const year = dateObject.getFullYear();
        const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObject.getDate().toString().padStart(2, '0');

        setStartDateUsersState({
            ...startDateUsersState,
            [user]: `${year}-${month}-${day}`
        })
    }

    const onSaveUsersData = (id: string) => {
        setButtonClicked(id);
        const userToUpdate: any = props.allPurchase?.filter((user: any) => user.email === id) || [];

        const active = props.user?._active || true;
        const activeDays = activeDaysUsersState[id];
        const limitDate = limiteDateUsersState[id];
        const userType = activeDays <= 16 ? 0 : (activePlanState[id].toLowerCase().replace(" ", "").includes("valle") ? 3: 2);
        const startDate = startDateUsersState[id];

        updateUser(userToUpdate[0].email, active, activeDays, limitDate, userType + "", startDate);
        
    }

    const onSavePricesData = () => {
        updatePrices(id, monthlyPrice, valleyPrice, quarterlyPrice, semesterPrice, ticket01Price, ticket04Price, ticket08Price, ticket12Price, ticket16Price)
        
    }

    React.useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);
    React.useEffect(() => {
        setLoadingPrices(props.loadingSignOut);
    }, [props.loadingSignOut]);

    return (
        <div className='dashboard_container'>
            <div>
                <div className="purchase_title">Precios de Planes</div>
                <div className="purchase_prices">
                <table className="styled-table">
                    <thead>
                    <tr>
                        <th>Mensual</th>
                        <th>Valley</th>
                        <th>Trimestral</th>
                        <th>Semestral</th>
                        <th>1 día</th>
                        <th>4 días</th>
                        <th>8 días</th>
                        <th>12 días</th>
                        <th>16 días</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.allPrices?.map((price: any, index: number) => (
                        <tr key={index}>
                            <td>
                                <input
                                style={{width: "50px"}}
                                type="text"
                                value={monthlyPrice}
                                onChange={(e) => handleActiveMonthlyPrice(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                style={{width: "50px"}}
                                type="text"
                                value={valleyPrice}
                                onChange={(e) => handleActiveValleyPrice(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                style={{width: "50px"}}
                                type="text"
                                value={quarterlyPrice}
                                onChange={(e) => handleActiveQuarterlyPrice(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                style={{width: "50px"}}
                                type="text"
                                value={semesterPrice}
                                onChange={(e) => handleActiveSemesterPrice(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                style={{width: "50px"}}
                                type="text"
                                value={ticket01Price}
                                onChange={(e) => handleActiveTicket01Price(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                style={{width: "50px"}}
                                type="text"
                                value={ticket04Price}
                                onChange={(e) => handleActiveTicket04Price(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                style={{width: "50px"}}
                                type="text"
                                value={ticket08Price}
                                onChange={(e) => handleActiveTicket08Price(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                style={{width: "50px"}}
                                type="text"
                                value={ticket12Price}
                                onChange={(e) => handleActiveTicket12Price(e.target.value)}
                                />
                            </td>
                            <td>
                                <input
                                style={{width: "50px"}}
                                type="text"
                                value={ticket16Price}
                                onChange={(e) => handleActiveTicket16Price(e.target.value)}
                                />
                            </td>
                            <td>
                            <button className="signout_button_item" style={{backgroundColor: "white", border: "1px solid white", width: "fit-content"}} onClick={() => onSavePricesData()}>
                                {
                                    (loadingPrices) ?
                                    <Box sx={{ display: 'flex'}}>
                                        <CircularProgress size="14px" style={{color: "gray"}} />
                                    </Box> : 'Actualizar Precios'
                                }
                            </button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                
                </div>
            </div>
            <div className="purchase_title">Pedidos Activos</div>
            <table className="styled-table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Plan</th>
                    <th>Active Days</th>
                    <th>Start Date</th>
                    <th>Limit Date</th>
                    <th>Email</th>
                </tr>
                </thead>
                <tbody>
                {props.allPurchase?.map((user: any, index: number) => (
                    <tr key={index}>
                    <td>{`${user.firstName} ${user.lastName}`}</td>
                    
                    <td>
                        <input
                        style={{width: "100px"}}
                        type="text"
                        value={activePlanState[user.email]}
                        disabled={true}
                        />
                    </td>
                    <td>
                        <input
                        style={{width: "50px"}}
                        type="number"
                        value={activeDaysUsersState[user.email]}
                        onChange={(e) => handleActiveDaysUser(user.email, parseInt(e.target.value))}
                        />
                    </td>
                    <td>
                        <input
                        type="date"
                        value={startDateUsersState[user.email]}
                        onChange={(e) => handleActiveStartDateUser(user.email, e.target.value)}
                        />
                    </td>
                    <td>
                        <input
                        type="date"
                        value={limiteDateUsersState[user.email]}
                        onChange={(e) => handleActiveLimitDateUser(user.email, e.target.value)}
                        />
                    </td>
                    <td>{user.email}</td>
                    <td>
                        <button className="signout_button_item" style={{backgroundColor: "white", border: "1px solid white"}} onClick={() => onSaveUsersData(user.email)}>
                            {
                                (!loading) ? "Activar Plan" : 
                                buttonClicked === user.email ?
                                <Box sx={{ display: 'flex'}}>
                                    <CircularProgress size="14px" style={{color: "gray"}} />
                                </Box> : 'Activar Plan'
                            }
                        </button>
                    </td>
                    
                    </tr>
                ))}
                </tbody>
            </table>
        </div>



    )
};

export default withAuthAndVisibility(DashBoardPurchaseComponent);
