import { UserSchedueleData } from "../../../../components/AppPresenter";
import { User } from "../../../authentication/entities/user";
import { Metrics } from "../../../metrics/entities";
import { Schedule } from "../../../schedule/entities/schedule";
import { Purchase } from "../../../shopping/entities";
import { Prices } from "../../../shopping/entities/prices";

export interface StateType {
  readonly user: User | null;
  readonly userSchedule: UserSchedueleData[] | null;
  readonly loading: boolean;
  readonly loadingSignOut: boolean;
  readonly allSchedule: Schedule[] | null;
  readonly allUsers: User[] | null;
  readonly allPurchase: Purchase[] | null;
  readonly allPrices: Prices[] | null;
  readonly actionMessage: String | null;
  readonly allMetrics: Metrics[] | null;
  readonly metricsById: any | null;
}

export const initialState: StateType = {
  user: null,
  userSchedule: null,
  allSchedule: null,
  loading: false,
  loadingSignOut: false,
  allUsers: null,
  allPurchase: null,
  allPrices: null,
  actionMessage: null,
  allMetrics: null,
  metricsById: null
};
