import React from 'react';

import './CalendarComponentStyle.css';
type ScrollAreaProps = {
  children: React.ReactNode;
  className?: string;
};

const ScrollArea: React.FC<ScrollAreaProps> = ({ children, className }) => {
  return <div className={`scroll_area ${className}`}>{children}</div>;
};

export default ScrollArea;
