import React, { useState } from 'react';
import './LeaderBoardComponentStyles.css';
import HeaderComponent from '../Header/HeaderComponent';

import { Props } from '../AppPresenter';
import withAuthAndVisibility from '../UserAuth';
import FormControl from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import Select from '@mui/material/Select/Select';
import MenuItem from '@mui/material/MenuItem/MenuItem';

interface PropsType {
  signOut: () => void;
  props: Props;
  onSave: (
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumnber: string
  ) => void;
  OnSaveMetrics: (
    id: string,
    deadlift: number,
    backsquat: number,
    frontsquat: number,
    cleanandjerk: number,
    snatch: number,
    shoulderpress: number,
    benchpress: number,
  )=> void
}

enum Plan {
  'Por días',
  'Admin',
  'Por Mes',
  'Días Valle'
}

const LeaderBoardComponent : React.FC<PropsType> = ({ signOut, props, onSave, OnSaveMetrics }) => {

  const [ metricSelected, setMetricSelected ] = useState("backsquat");
  
  const [ metricData, setMetricData ] = useState<any[]>([]);
  
  let metricNames: any = [];
  Object.keys(props.allMetrics ? props.allMetrics[0] : []).forEach(key => {
      if(key !== 'id') metricNames.push(key)
  });


  const handleChange = (value: string) => {
    setMetricSelected(value);

    const backSquatMetrics = props.allMetrics?.sort((a: any, b: any) => {
      if (((parseInt(b[value]) - parseInt(a[value])) > 0)) {
        return 1
      } else {
        return -1
      }
    
    }).filter((metric: any) => metric[value] !== 0) as any[];
    setMetricData(backSquatMetrics);
  }

  React.useEffect(() => {
    handleChange('backsquat')
  }, [])

  return (
    <>
    <HeaderComponent />
    <div className='leaderboard_container'>
      <p className="schedule_title">Ranking de Métricas</p>
      <div className='leaderboard_filter'>
      <FormControl sx={{
        m: 2,
        '& .MuiOutlinedInput-root': {
          '& fieldset': { borderColor: 'white' },
          '&:hover fieldset': { borderColor: 'white' }, // Optional: hover effect
        },
        '& .MuiInputLabel-root': {
          color: 'white', // Label color
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: 'white', // Label color when focused
        },
        '& .MuiOutlinedInput-input': {
          color: 'white',
          '::placeholder': {
            color: 'red', // Placeholder color
            opacity: 1, // To ensure full opacity for the placeholder text
          },
        },
      }}
  >
          <InputLabel id="demo-simple-select-label">Metric</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            style={{}}
            value={metricSelected}
            label={metricSelected}
            onChange={(e) => handleChange(e.target.value)}
          >
            {
              metricNames.map((item: string) => {
                return <MenuItem value={item}>{item}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </div>
    
      <div>
        {
          metricData?.map((user: any, idx: number) => {
            return(
              <div className='leader_board_table_item' style={idx === 0 ? {border: '1px solid #E8D200'}: {}}>
                <div>
                  {``}
                  {`${idx + 1}. 
                  ${(props.allUsers?.filter((item: any) => item.email === user.id)[0] as any).firstName.split(' ')[0]}
                  ${(props.allUsers?.filter((item: any) => item.email === user.id)[0] as any).lastName.split(' ')[0]}
                  `}
                </div>
                <div>
                  {user[metricSelected]} lbs
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
   
     
  </>
    
  );
};

export default withAuthAndVisibility(LeaderBoardComponent);
