import { Either, left, right } from '../../utils/CheckResponseSide';
import { MetricsData } from '../entities/metrics';
import { GetAllMetricsService } from '../useCases/getAllMetricsInteractor';
import { GetMetricsByIdService } from '../useCases/getMetricsByIdInteractor';
import { UpdateMetricsByIdService } from '../useCases/updateMetricsByIdInteractor';

const GRAPHQL_ENDPOINT = "https://3ue6aegggvedndfjtxez3hjnlq.appsync-api.us-east-1.amazonaws.com/graphql";
const GRAPHQL_API_KEY = "da2-va6z7bp4p5cp7k5ambqjzxmngi";
const response: any = {};

export class MetricsServices implements 
    GetAllMetricsService,
    GetMetricsByIdService,
    UpdateMetricsByIdService
  {

  async getAllMetricsImpl(): Promise<any> {
    const query = /* GraphQL */ `
      query MyQuery {
        listMetrics(limit: 1000) {
          items {
            id
            backsquat
            benchpress
            cleanandjerk
            deadlift
            frontsquat
            shoulderpress
            snatch
          }
        }
      }
    `;

    const options = {
      method: "POST",
      headers: {
        "x-api-key": GRAPHQL_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query: query }),
    };
    try {
      const res = await fetch(GRAPHQL_ENDPOINT, options);
      const response = await res.json();
      return right(response.data.listMetrics.items);
    } catch (error) {
      console.log(error, "error")
      response.statusCode = 400;
      response.body = {
        errors: [
          {
            message: error,
            stack: error,
          },
        ],
      };
    }
  }

  async getMetricsByIdImpl(id: string): Promise<any> {
    const query = /* GraphQL */ `
      query MyQuery {
        getMetrics(id: "${id}") {
          id
          backsquat
          benchpress
          cleanandjerk
          deadlift
          frontsquat
          shoulderpress
          snatch
        }
      }
    `;

    const options = {
      method: "POST",
      headers: {
        "x-api-key": GRAPHQL_API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ query: query }),
    };
    try {
      const res = await fetch(GRAPHQL_ENDPOINT, options);
      const response = await res.json();
      return right(response.data.getMetrics);
    } catch (error) {
      console.log(error, "error")
      response.statusCode = 400;
      response.body = {
        errors: [
          {
            message: error,
            stack: error,
          },
        ],
      };
    }
  }

  async updateUserMetricsByIdImpl(
    id: string,
    deadlift: number,
    backsquat: number,
    frontsquat: number,
    cleanandjerk: number,
    snatch: number,
    shoulderpress: number,
    benchpress: number
  ) {
    const query = /* GraphQL */ `
      mutation updateMetrics(
        $id: ID!
        $deadlift: Int
        $backsquat: Int
        $frontsquat: Int
        $cleanandjerk: Int
        $snatch: Int
        $shoulderpress: Int
        $benchpress: Int
      ) {
        updateMetrics(
          input: {
            id: $id
            deadlift: $deadlift
            backsquat: $backsquat
            frontsquat: $frontsquat
            cleanandjerk: $cleanandjerk
            snatch: $snatch
            shoulderpress: $shoulderpress
            benchpress: $benchpress
          }
        ) {
          id
          deadlift
          backsquat
          frontsquat
          cleanandjerk
          snatch
          shoulderpress
          benchpress
        }
      }
    `;
  
    const variables = {
      id,
      deadlift,
      backsquat,
      frontsquat,
      cleanandjerk,
      snatch,
      shoulderpress,
      benchpress,
    };
  
    const options = {
      method: "POST",
      headers: {
        "x-api-key": GRAPHQL_API_KEY,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ query, variables })
    };

    const result = await this.getMetricsByIdImpl(id);
    console.log(result.value);
    
    if (result.value) {
      try {
        const res = await fetch(GRAPHQL_ENDPOINT, options);
        console.log(res, "updating");
        
        const data = await res.json();
        return data;
      } catch (error) {
        console.error(error, "error");
        throw new Error("Failed to update user profile");
      }
    } else {
      const createQuery = /* GraphQL */ `
        mutation createMetrics(
          $id: ID!
          $deadlift: Int
          $backsquat: Int
          $frontsquat: Int
          $cleanandjerk: Int
          $snatch: Int
          $shoulderpress: Int
          $benchpress: Int
        ) {
          createMetrics(
            input: {
              id: $id
              deadlift: $deadlift
              backsquat: $backsquat
              frontsquat: $frontsquat
              cleanandjerk: $cleanandjerk
              snatch: $snatch
              shoulderpress: $shoulderpress
              benchpress: $benchpress
            }
          ) {
            id
            deadlift
            backsquat
            frontsquat
            cleanandjerk
            snatch
            shoulderpress
            benchpress
          }
        }
      `;

      const createVariables = {
        id,
        deadlift,
        backsquat,
        frontsquat,
        cleanandjerk,
        snatch,
        shoulderpress,
        benchpress,
      };
    
      const createOptions = {
        method: "POST",
        headers: {
          "x-api-key": GRAPHQL_API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ query: createQuery, variables: createVariables })
      };

      const createRes = await fetch(GRAPHQL_ENDPOINT, createOptions);
      const createData = await createRes.json();
      return createData;
    }
  
    
  }
  
}
