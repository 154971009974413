import { Box, CircularProgress } from '@mui/material';
import HeaderComponent from '../Header/HeaderComponent';
import './InvalidUserComponentStyles.css';
import { useEffect, useState } from 'react';
import { Props } from '../AppPresenter';
import { useNavigate } from 'react-router-dom';
import withAuthAndVisibility from '../UserAuth';
import { checkingUser } from '../../utils/checkingUser';

interface PropsType {
    signOut: () => void;
    props: Props;
  }

const InvalidUserComponent: React.FC<PropsType> = ({ signOut, props }) => {

  const navigate = useNavigate();

  const [loadingSignOut, setLoadingSignOut] = useState(props.loadingSignOut);

  useEffect(() => {
    const validUser = checkingUser(props.user);
    if (validUser) {
      navigate('/user');
    }
    if (props.user?._userStatus !== 'activeUser') {
      navigate('/signin');
    }
  }, [props.user])

  return (
    <>
        <HeaderComponent />
        <div className="invalid_container">
        <>No tienes un plan activo, comunicate con nosotros para habilitarte un plan que te permite empezar a cumplir tus metas</>
        </div>
        <div className="schedule_footer">
        <button className="signout_button_item" onClick={signOut}>
          {
              !loadingSignOut ? "Salir" : 
              <Box sx={{ display: 'flex'}}>
                <CircularProgress size="16px" style={{color: "gray"}} />
              </Box>
            }
        </button>
    </div>
    </>
    
  )
}

export default InvalidUserComponent;
