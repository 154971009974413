import { Either } from "../../utils/CheckResponseSide";
import { User } from "../entities";

export interface sendRecoveryPasswordService {
  sendEmailRecoveryCode: (email: string) => Promise<Either<Error, User>> ;
  sendPasswordRecoveryCode: (email: string, code: string, password: string) => Promise<Either<Error, User>> ;
}

export class SendRecoveryPasswordCodeInteractor {
  sendEmailRecoveryService: sendRecoveryPasswordService;

  constructor(sendEmailRecoveryService: sendRecoveryPasswordService) {
    this.sendEmailRecoveryService = sendEmailRecoveryService;
  }

  async sendCode(email: string): Promise<Either<Error, User>> {
    const response = await this.sendEmailRecoveryService.sendEmailRecoveryCode(email);
    return response;
  }
  async sendNewPassword(email: string, code: string, password: string): Promise<Either<Error, User>> {
    const response = await this.sendEmailRecoveryService.sendPasswordRecoveryCode(email, code, password);
    return response;
  }
}