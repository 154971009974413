import { User } from "../../app/authentication/entities/user";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import './LoadingComponentStyles.css';

interface PropsType {
  message: String;
}

export const LoadingComponent : React.FC<PropsType> = ({ message }) => {
  return (
   
    <div className="loading_container">
      <Box>
        <div style={{width: "100%", textAlign: "center"}}>
          <CircularProgress style={{color: "white"}} />
        </div>
        <Box sx={{ display: 'flex' }}>
          <p>{message}</p>
        </Box>
        
      </Box>
      
    </div>
    
    
  )
}