import React from "react";
import { useNavigate } from "react-router-dom";
import './HeaderComponentStyles.css';

import { useLocation } from "react-router-dom";
import logo from '../../assets/logos/dukbox_log.png';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';

const HeaderComponent: React.FC = () => {

    const navigate = useNavigate();

    const location = useLocation();

    return (
        <div className='header_container'>
            <img className='header_logo' src={logo} alt="" />
            {
                !location.pathname.includes("signin") &&
                    <div className="left_header_elements">

                        <div className="header_info" onClick={() => {
                            navigate("/shopping");
                        }}>
                            <ShoppingCartIcon 
                                className={
                                    location.pathname.includes("shopping") ?
                                        "header_shopping_logo_selected" : "header_shopping_logo"
                                }
                                sx={
                                    location.pathname.includes("shopping") ?
                                    {
                                        fill: "#E8D200"
                                    } : 
                                    {}
                                }
                            />
                        </div>

                        <div className="header_info" onClick={() => {
                            navigate("/user");
                        }}>
                            <CalendarMonthIcon 
                                className={
                                    location.pathname.includes("user") ?
                                        "header_shopping_logo_selected" : "header_shopping_logo"
                                }
                                sx={
                                    location.pathname.includes("user") ?
                                    {
                                        fill: "#E8D200"
                                    } : 
                                    {}
                                }
                            />
                            
                        </div>
                        <div className="header_info" onClick={() => {
                            navigate("/leaderboard");
                        }}>
                            <LeaderboardIcon
                                className={
                                    location.pathname.includes("leaderboard") ?
                                        "header_shopping_logo_selected" : "header_shopping_logo"
                                }
                                sx={
                                    location.pathname.includes("leaderboard") ?
                                    {
                                        fill: "#E8D200"
                                    } : 
                                    {}
                                }
                            />
                            
                        </div>
                        <div className="header_info" onClick={() => {
                            navigate("/profile");
                        }}>
                            <AccountCircleIcon 
                                className={
                                    location.pathname.includes("profile") ?
                                        "header_shopping_logo_selected" : "header_shopping_logo"
                                }
                                sx={
                                    location.pathname.includes("profile") ?
                                    {
                                        fill: "#E8D200"
                                    } : 
                                    {}
                                }
                            />
                            
                        </div>
                                
                        {
                            /**
                            * 
                                <Avatar className="header_profile_logo" onClick={() => {
                                navigate("/profile");
                                }}>
                                    {`${user?._firstName[0]?.toLocaleUpperCase()}${user?._lastName[0]?.toLocaleUpperCase()}`}
                                </Avatar>
                            */
                        }
                        
                    </div>
            }
        </div>
    )
};

export default HeaderComponent;
