import { Either } from "../../utils/CheckResponseSide";
import { Schedule } from "../entities";

export interface GetUserSchedulesService {
  getUserSchedulesImpl: (id: string) => Promise<Either<Error, Schedule>> ;
}

export class GetUserSchedulesInteractor {
  getUserScheduleService: GetUserSchedulesService;

  constructor(getUserScheduleService: GetUserSchedulesService) {
    this.getUserScheduleService = getUserScheduleService;
  }

  async getUserSchedules(id: string): Promise<Either<Error, Schedule>> {
    
    const response = await this.getUserScheduleService.getUserSchedulesImpl(id);
    return response;
  }
}