import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import './SignInComponentStyles.css';

import HeaderComponent from "../Header/HeaderComponent";
import { emailValidation, passwordValidation } from "../../utils/validation";
import { Box, CircularProgress } from "@mui/material";
import { Props } from "../AppPresenter";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const messagesFromLogging: { [key: string]: string } = {
  "User does not exist.": "El usuario no existe",
  "none": "",
  "Incorrect username or password.": "Usuario o contraseña incorrecta",
  "userNeedConfirmation": "El usuario necesita ser confirmado"
};

interface PropsType {
  onClick: (email: string, password: string) => void;
  props: Props
}

const SignInComponent: React.FC<PropsType> = ({ onClick, props }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [loading, setLoading] = useState(props.loading);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = () => {
    const isEmailValid = emailValidation(email);
    //const isPasswordValid = passwordValidation(password);
    //fix this to check validation
    //fix this to check validataion

    if(isEmailValid === "" && password !== "") {
      onClick(email, password);
      const message = messagesFromLogging[props.user?._userStatus.split(": ")[1] ? props.user?._userStatus.split(": ")[1] : props.user?._userStatus.split(": ")[0] as any];
      console.log(message, props.user?._userStatus);
      
      setErrorMessage(message);
    } else {
      setErrorMessage("Los campos son obligatorios");
    }
  };

  const handleRegistration = () => {
    navigate("/signup");
  };

  const handleRecovery = () => {
    navigate("/recovery")
  }

  React.useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  React.useEffect(() => {
    const message = messagesFromLogging[props.user?._userStatus?.split(": ")[1] as any]
    setErrorMessage(message);
    console.log(props.user?._userStatus);
    if (props.user?._userStatus === 'activeUser') {
      navigate('/user');
    }
    if (props.user?._userStatus === "userNeedConfirmation") {
      navigate('/confirmation');
    }
  }, [props.user?._userStatus]);

  return (
    <>
    <HeaderComponent />
    <div className="signin_container">
      <div className="signin_sub_container">
      <p className="signin_title">Inicia sesión para reservar</p>
      <form onSubmit={handleSubmit}>
        
        <div className="signin_input_container">
          <div className="signin_title_label">
              Email
          </div>
          <input
            className="signin_input_item"
            type="text"
            value={email}
            placeholder="email"
            onChange={handleChangeEmail}
          />
        </div>
        
        <div className="signin_input_container">
          <div className="signin_title_label">
              Contraseña
          </div>
          <input
            className="signin_input_item password"
            type={passwordVisible ? 'text' : 'password'}
            value={password}
            placeholder="contraseña"
            onChange={handleChangePassword}
          />
          <div
            onClick={togglePasswordVisibility}
            className="password_toggle_btn"
          >
            {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </div>
        </div>
        <p className="error_sign_message">{errorMessage}</p>
        <div className="signin_button">
          <button className="signin_button_item" type="button" onClick={handleSubmit}>
            {
              !loading ? "Iniciar Sesión" : 
              <Box sx={{ display: 'flex'}}>
                <CircularProgress size="16px" style={{color: "gray"}} />
              </Box>
            }
            
          </button>
        </div>
        
      </form>
      
      <div className="signin_registration_container">
        <div className="signin_registration_content">
          <p>¿Quieres unirte al equipo?</p>
          <div className="signin_registration_link" onClick={handleRegistration}>Registro</div>
        </div>
      </div>
      <div className="signin_registration_container">
        <div className="signin_registration_content">
          <div className="signin_recovery_link" onClick={handleRecovery}>olvidé mi contraseña</div>
        </div>
      </div>

      </div>
      
    </div>
    </>
    
  );
};

export default SignInComponent;
