import { Either } from "../../utils/CheckResponseSide";
import { Credential, User } from "../entities";

export interface SignUpService {
  signUpUserRegistration: (
    username: string,
    password: string,
    email: string,
    phone_number: string,
    given_name: string,
    family_name: string
  ) => Promise<Either<Error, User>> ;
}

export class SignUpInteractor {
  signUpService: SignUpService;

  constructor(signUpService: SignUpService) {
    this.signUpService = signUpService;
  }

  async signUp(
      username: string,
      password: string,
      email: string,
      phone_number: string,
      given_name: string,
      family_name: string
    ): Promise<Either<Error, User>> {
    const response = await this.signUpService.signUpUserRegistration(username, password, email, phone_number, given_name, family_name);
    return response;
  }
}