// useToast.ts
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Type for toast variants
type ToastVariant = 'default' | 'success' | 'error' | 'info' | 'warning' | 'destructive';

interface ToastOptions {
  title: string;
  description: string;
  variant?: ToastVariant;
}

const useToast = () => {
  const showToast = (options: ToastOptions) => {
    const { title, description, variant = 'default' } = options;

    switch (variant) {
      case 'success':
        toast.success(`${title}: ${description}`);
        break;
      case 'error':
      case 'destructive':
        toast.error(`${title}: ${description}`);
        break;
      case 'info':
        toast.info(`${title}: ${description}`);
        break;
      case 'warning':
        toast.warn(`${title}: ${description}`);
        break;
      default:
        toast(`${title}: ${description}`);
        break;
    }
  };

  return { showToast };
};

export default useToast;
