/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSchedules = /* GraphQL */ `
  subscription OnCreateSchedules(
    $filter: ModelSubscriptionSchedulesFilterInput
  ) {
    onCreateSchedules(filter: $filter) {
      id
      date
      cluster0506
      cluster0607
      cluster0708
      cluster0809
      cluster1617
      cluster1718
      cluster1819
      cluster1920
      cluster2021
    }
  }
`;
export const onUpdateSchedules = /* GraphQL */ `
  subscription OnUpdateSchedules(
    $filter: ModelSubscriptionSchedulesFilterInput
  ) {
    onUpdateSchedules(filter: $filter) {
      id
      date
      cluster0506
      cluster0607
      cluster0708
      cluster0809
      cluster1617
      cluster1718
      cluster1819
      cluster1920
      cluster2021
    }
  }
`;
export const onDeleteSchedules = /* GraphQL */ `
  subscription OnDeleteSchedules(
    $filter: ModelSubscriptionSchedulesFilterInput
  ) {
    onDeleteSchedules(filter: $filter) {
      id
      date
      cluster0506
      cluster0607
      cluster0708
      cluster0809
      cluster1617
      cluster1718
      cluster1819
      cluster1920
      cluster2021
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($filter: ModelSubscriptionUserFilterInput) {
    onCreateUser(filter: $filter) {
      id
      email
      firstName
      lastName
      active
      schedule
      phoneNumber
      activeDays
      limitDate
      userType
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($filter: ModelSubscriptionUserFilterInput) {
    onUpdateUser(filter: $filter) {
      id
      active
      activeDays
      limitDate
      userType
      startDate
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($filter: ModelSubscriptionUserFilterInput) {
    onDeleteUser(filter: $filter) {
      id
      email
      firstName
      lastName
      active
      schedule
      phoneNumber
      activeDays
      limitDate
      userType
      createdAt
      updatedAt
      __typename
    }
  }
`;
