import { Either } from "../../utils/CheckResponseSide";
import { MetricsData } from "../entities/metrics";

export interface UpdateMetricsByIdService {
  updateUserMetricsByIdImpl: (
    id: string,
    deadlift: number,
    backsquat: number,
    frontsquat: number,
    cleanandjerk: number,
    snatch: number,
    shoulderpress: number,
    benchpress: number
  ) => Promise<Either<Error, MetricsData[]>> ;
}

export class UpdateMetricsByIdInteractor {
  updatetMetricsByIdService: UpdateMetricsByIdService;

  constructor(updateMetricsByIdService: UpdateMetricsByIdService) {
    this.updatetMetricsByIdService = updateMetricsByIdService;
  }

  async updateMetricsById(
    id: string,
    deadlift: number,
    backsquat: number,
    frontsquat: number,
    cleanandjerk: number,
    snatch: number,
    shoulderpress: number,
    benchpress: number,
  ): Promise<Either<Error, MetricsData[]>> {
    const response = await this.updatetMetricsByIdService.updateUserMetricsByIdImpl(
      id,
      deadlift,
      backsquat,
      frontsquat,
      cleanandjerk,
      snatch,
      shoulderpress,
      benchpress,
    );
    return response;
  }
}