
import { User } from "../../../authentication/entities/user";
import {initialState, StateType} from "./state";

const UPDATE_USER = "user/update";
const UPDATE_USER_FIELDS = "user/update_fields"
const SIGN_OUT = "user/signOut";

const ALL_USERS = "user/all_users";

type StateSlice = StateType["user"];
type StateSliceAllUsers = StateType["allUsers"];

interface ActionType {
  type: string,
}

export interface UpdateUserActionType {
  type: string,
  userFields: any
}

export interface UpdateAllUsersActionType {
  type: string,
  allUsers: User[] | null,
}

export const userSelector = (state: StateType): StateSlice => state.user;
export const updateUserAction = (type: string, userFields: any): UpdateUserActionType => ({
  type,
  userFields
});

export const allUsersSelector = (state: StateType): StateSliceAllUsers => state.allUsers;
export const updateAllUsersAction = (allUsers: User[] | null): UpdateAllUsersActionType => ({
  type: ALL_USERS,
  allUsers
});

export const signOutAction = (): ActionType => ({
  type: SIGN_OUT,
});

const signOutHandler = () => null;

const updateHandler = (
  state: StateSlice,
  action: UpdateUserActionType
): StateType["user"] => {
  return action.userFields;
};

const updateAllUsersHandler = (
  state: StateSliceAllUsers,
  action: UpdateAllUsersActionType
): StateType["allUsers"] => {
  return action.allUsers;
};

export const userReducer = (
  state: StateSlice = initialState.user,
  action: UpdateUserActionType,
): StateSlice => {
  switch (action.type) {
    case UPDATE_USER:
      return updateHandler(state, action);
    case UPDATE_USER_FIELDS:
      const { userFields } = action;
      if (state?._email === userFields._email) {
        return {
          ...state,
          ...userFields,
        };
      } else {
        return {
          ...state as any
        };
      }
      
    case SIGN_OUT:
      return signOutHandler();
    default:
      return state;
  }
};

export const allUsersReducer = (
  state: StateSliceAllUsers = initialState.allUsers,
  action: UpdateAllUsersActionType,
): StateSliceAllUsers => {
  switch (action.type) {
    case ALL_USERS:
      return updateAllUsersHandler(state, action);
    default:
      return state;
  }
};