import { User } from "../../authentication/entities";
import { Either } from "../../utils/CheckResponseSide";
import { Purchase } from "../entities/shopping";

export interface DeletePurchaseService {
  deletePurchaseImpl: (data: any) => Promise<Either<Error, Purchase[]>> ;
}

export class DeletePurchaseInteractor {
  deletePurchaseService: DeletePurchaseService;

  constructor(deletePurchaseService: DeletePurchaseService) {
    this.deletePurchaseService = deletePurchaseService;
  }

  async deletePurchase(data: any): Promise<Either<Error, Purchase[]>> {
  
    const response = await this.deletePurchaseService.deletePurchaseImpl(data);
    return response;
  }
}