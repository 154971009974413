import { configureStore } from "./app/store/frameworks/redux";
import * as React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import App from "./components/AppPresenter";

import './index.css';

import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';

Amplify.configure(config);

const element = document.getElementById("root");
const ConnectedApp = (
  <Provider store={configureStore()}>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </Provider>
);

ReactDOM.createRoot(element).render(ConnectedApp);
