import { Either } from "../../utils/CheckResponseSide";
import { Prices } from "../entities/prices";
type PricesData = {
  id: string,
  monthly: string,
  valley: string,
  quarterly: string,
  semester: string,
  ticket01: string,
  ticket04: string,
  ticket08: string,
  ticket12: string,
  ticket16: string,
  };
export interface UpdatePricesService {
  updatePricesInfoImpl: (
    id: string,
    monthly: string,
    valley: string,
    quarterly: string,
    semester: string,
    ticket01: string,
    ticket04: string,
    ticket08: string,
    ticket12: string,
    ticket16: string,
  ) => Promise<Either<Error, Prices>> ;
}

export class UpdatePricesInteractor {
  updatePricesService: UpdatePricesService;

  constructor(updatePricesService: UpdatePricesService) {
    this.updatePricesService = updatePricesService;
  }

  async updatePricesInfo(
    id: string,
    monthly: string,
    valley: string,
    quarterly: string,
    semester: string,
    ticket01: string,
    ticket04: string,
    ticket08: string,
    ticket12: string,
    ticket16: string,
  ): Promise<Either<Error, Prices>> {
    const response = await this.updatePricesService.updatePricesInfoImpl(
      id,
      monthly,
      valley,
      quarterly,
      semester,
      ticket01,
      ticket04,
      ticket08,
      ticket12,
      ticket16,
    );
    return response;
  }
}