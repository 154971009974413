// src/components/ui/button.tsx
import React from 'react';
import SaveIcon from '@mui/icons-material/Save';
import './buttonComponentStyle.css';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'outline' | 'secondary' | 'arrow' | 'selected' | 'active';
}

const Button: React.FC<ButtonProps> = ({ children, variant = 'default', ...props }) => {
  const classNames = `btn_${variant}`;
  return (
      <button className={classNames} {...props}>
        {children}
      </button>
  );
};

export default Button;
