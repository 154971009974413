import { Either } from "../../utils/CheckResponseSide";
import { Schedule } from "../entities";
type UserSchedueleData = {
    id: number;
    table: string[];
    hours: string[];
    user: string;
  };
export interface UpdateUserSchedulesService {
    updateUserScheduleImpl: (data: UserSchedueleData[]) => Promise<Either<Error, Schedule>> ;
    updateUserInfoImpl: (
      id: string,
      active: boolean,
      activeDays: number,
      limitDate: string,
      userType: string,
      startDate: string
    ) => Promise<Either<Error, void>>;
}

export class UpdateUserSchedulesInteractor {
  updateUserScheduleService: UpdateUserSchedulesService;

  constructor(updateUserScheduleService: UpdateUserSchedulesService) {
    this.updateUserScheduleService = updateUserScheduleService;
  }

  async updateUserSchedule(data: UserSchedueleData[]): Promise<Either<Error, Schedule>> {
    
    const response = await this.updateUserScheduleService.updateUserScheduleImpl(data);
    return response;
  }

  async updateUserInfo(
      id: string,
      active: boolean,
      activeDays: number,
      limitDate: string,
      userType: string,
      startDate: string
    ): Promise<Either<Error, void>> {
    
    const response = await this.updateUserScheduleService.updateUserInfoImpl(id, active, activeDays, limitDate, userType, startDate);
    return response;
  }
}