import { UserSchedueleData } from "../../../../components/AppPresenter";
import { Schedule } from "../../../schedule/entities/schedule";
import { StateType, initialState } from "./state";

const UPDATE_USER_SCHEDULE = "schedule/update";
const UPDATE_ALL_SCHEDULE = "allschedule/update";

type StateSliceUser = StateType["userSchedule"];
type StateSliceAll = StateType["allSchedule"];

export interface UpdateUserScheduleActionType {
  type: string,
  userSchedule: UserSchedueleData[] | null,
}

export interface UpdateAllScheduleActionType {
  type: string,
  allSchedule: Schedule[] | null,
}

export const userScheduleSelector = (state: StateType): StateSliceUser => state.userSchedule;
export const updateUserScheduleAction = (userSchedule: UserSchedueleData[] | null): UpdateUserScheduleActionType => ({
  type: UPDATE_USER_SCHEDULE,
  userSchedule,
});

export const allScheduleSelector = (state: StateType): StateSliceAll => state.allSchedule;
export const updateAllScheduleAction = (allSchedule: Schedule[] | null,): UpdateAllScheduleActionType => ({
  type: UPDATE_ALL_SCHEDULE,
  allSchedule,
});

const updateUserHandler = (
  state: StateSliceUser,
  action: UpdateUserScheduleActionType
): StateType["userSchedule"] => {
  return action.userSchedule;
};

const updateAllHandler = (
  state: StateSliceAll,
  action: UpdateAllScheduleActionType
): StateType["allSchedule"] => {
  return action.allSchedule;
};

export const scheduleReducer = (
  state: StateSliceUser = initialState.userSchedule,
  action: UpdateUserScheduleActionType,
) => {
  switch (action.type) {
    case UPDATE_USER_SCHEDULE:
      return updateUserHandler(state, action);
    default:
      return state;
  }
};

export const allScheduleReducer = (
  state: StateSliceAll = initialState.allSchedule,
  action: UpdateAllScheduleActionType,
) => {
  switch (action.type) {
    case UPDATE_ALL_SCHEDULE:
      return updateAllHandler(state, action);
    default:
      return state;
  }
};
