import { Schedule } from "../../schedule/entities";

export class User {
    _id: string;
    _firstName: string;
    _lastName: string;
    _userStatus: string;
    _email: string;
    _schedule: string;
    _active: boolean | null;
    _activeDays: number;
    _limitDate: Date;
    _userType: number;
    _startDate: Date;
    _phoneNumber: string;


  constructor(
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      userStatus: string,
      schedule: string,
      active: boolean | null,
      activeDays: number,
      limitDate: Date,
      userType: number,
      startDate: Date,
      phoneNumber: string
    ) {
    if (isEmptyOrNull(firstName) || isEmptyOrNull(lastName)) {
      throw new Error("You must fill first name and last name");
    }
    this._id = id;
    this._firstName = firstName.toLocaleLowerCase();
    this._lastName = lastName.toLocaleLowerCase();
    this._email = email;
    this._userStatus = userStatus;
    this._schedule = schedule;
    this._active = active;
    this._activeDays = activeDays;
    this._limitDate = limitDate;
    this._userType = userType;
    this._startDate = startDate;
    this._phoneNumber = phoneNumber;
  }

  get name(): string {
    return `${this._firstName} ${this._lastName}`;
  }
}

function isEmptyOrNull(word: string) {
  //return !word || word.trim().length === 0;
  return false;
}