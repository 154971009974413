import { Either } from "../../utils/CheckResponseSide";

export interface UpdateUserProfileInfoService {
    updateUserProfileInfoImpl: (
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string
    ) => Promise<Either<Error, void>>;
}

export class UpdateUserProfileInfoInteractor {
  updateUserProfileInfoService: UpdateUserProfileInfoService;

  constructor(updateUserProfileInfoService: UpdateUserProfileInfoService) {
    this.updateUserProfileInfoService = updateUserProfileInfoService;
  }

  async updateUserProfileInfo(
      id: string,
      firstName: string,
      lastName: string,
      email: string,
      phoneNumber: string
    ): Promise<Either<Error, void>> {
    
    const response = await this.updateUserProfileInfoService.updateUserProfileInfoImpl(id, firstName, lastName, email, phoneNumber);
    return response;
  }
}