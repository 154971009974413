  // Validate email format
  export const emailValidation = (email:string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email.length === 0) {
      return "Este campo es obligatorio";
    }
    if (!emailRegex.test(email)) {
      return "Por favor, introduce una dirección de correo electrónico válida.";
    }
    return "";
  }

  export const phoneValidation = (phone: string) => {
    // Validate phone number format
    const phoneRegex = /^\d{10}$/;
    if (phone.length === 0) {
      return "Este campo es obligatorio";
    }
    if (!phoneRegex.test(phone)) {
        return "El número de teléfono debe contener exactamente 10 dígitos.";
    }
    return "";
  }
 
  export const passwordValidation = (password: string) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@&#$%*.]).{8,}$/;
    if (password.length === 0) {
      return "Este campo es obligatorio";
    }
    if (!passwordRegex.test(password)) {
        return "La contraseña debe tener al menos 8 caracteres e incluir al menos una letra minúscula, una letra mayúscula, un dígito y un carácter especial (!@&#$%*.).";
    }
    return "";
  }

  export const nameValidation = (name: string) => {
    if (name.length === 0) {
      return "Este campo es obligatorio";
    }
    return "";
  }
