import { Either } from "../../utils/CheckResponseSide";
import { MetricsData } from "../entities/metrics";

export interface GetMetricsByIdService {
  getMetricsByIdImpl: (id: string) => Promise<Either<Error, MetricsData[]>> ;
}

export class GetMetricsByIdInteractor {
  getMetricsByIdService: GetMetricsByIdService;

  constructor(getMetricsByIdService: GetMetricsByIdService) {
    this.getMetricsByIdService = getMetricsByIdService;
  }

  async getMetricsById(id: string): Promise<Either<Error, MetricsData[]>> {
    const response = await this.getMetricsByIdService.getMetricsByIdImpl(id);
    return response;
  }
}