import React, { useState } from 'react';
import './ProfileComponentStyles.css';
import HeaderComponent from '../Header/HeaderComponent';

import { Props } from '../AppPresenter';
import Avatar from '@mui/material/Avatar/Avatar';
import withAuthAndVisibility from '../UserAuth';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'; 
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  format
} from 'date-fns'

interface PropsType {
  signOut: () => void;
  props: Props;
  onSave: (
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumnber: string
  ) => void;
  OnSaveMetrics: (
    id: string,
    deadlift: number,
    backsquat: number,
    frontsquat: number,
    cleanandjerk: number,
    snatch: number,
    shoulderpress: number,
    benchpress: number,
  )=> void
}

enum Plan {
  'Por días',
  'Admin',
  'Por Mes',
  'Días Valle'
}

const ProfileComponent : React.FC<PropsType> = ({ signOut, props, onSave, OnSaveMetrics }) => {

  const navigate = useNavigate();

  const user = props.user;
  
  const [ userFirstName, setUserFirstName ] = useState(user?._firstName);
  const [ userLastName, setUserLastName ] = useState(user?._lastName);
  const [ userPhone, setUserPhone ] = useState(user?._phoneNumber);
  const [ selectedGender, setSelectedGender ] = useState('');
  const [birthValue, setBirthValue] = useState<Dayjs | null>(dayjs()); // Initialize with a Dayjs object

  const [ metricsById, setMetricsById ] = useState<any>(null);

  const [ openBirthModal, setOpenBirthModal ] = useState(false);

  const _onUpdateFirstName = (name: string) => {
    setUserFirstName(name);
  }
  const _onUpdateLastName = (name: string) => {
    setUserLastName(name);
  }
  const _onUpdatePhone= (phone: string) => {
    setUserPhone(phone);
  }

  const _onUpdateUser = () => {
    if (user?._email && userFirstName && userLastName && userPhone) {
      onSave(user?._email, userFirstName, userLastName, user?._email, userPhone);
    }
  };

  const _onSaveMetrics = () => {
    OnSaveMetrics(
      user?._email || '',
      metricsById.deadlift | 0,
      metricsById.backsquat | 0,
      metricsById.frontsquat | 0,
      metricsById.cleanandjerk | 0,
      metricsById.snatch | 0,
      metricsById.shoulderpress | 0,
      metricsById.benchpress | 0
    )
  }

  const _onUpdateMetrics = (metric: string, value: number) => {
    let newValue = {
      ...metricsById,
      [metric]: value
    }
    setMetricsById(newValue);
  }

  const handleChange = (event: any) => {
    setSelectedGender(event.target.value);
  };

  const handleDateChange = (date: Dayjs | null) => {
    setOpenBirthModal(!openBirthModal)
    setBirthValue(date); // Set the value with Dayjs object

    // If you need the date as a formatted string, you can do that separately
    if (date) {
      const formattedDate = `${date.year()}-${date.month() + 1}-${date.date()}`;
    }
  };

  React.useEffect(() => {
    if (props.user?._userStatus !== 'activeUser') {
      navigate('/signin');
    }
  }, [props.user?._userStatus])

  React.useEffect(() => {
      setMetricsById(props.metricsById)
  }, [props.user])

  return (
    <>
    <HeaderComponent />
   
    <div className='profile_body'>
        <Avatar className='profile_image' style={{width: "80px", height: "80px"}}>
          <p>{`${user?._firstName[0]?.toLocaleUpperCase()}${user?._lastName[0]?.toLocaleUpperCase()}`}</p>
        </Avatar>
    </div>
    <form className='profile_form'>
        <div className="profile_input_container">
          <div className="profile_title_label">
              Nombre
          </div>
          <input
            className="profile_input_item"
            type="text"
            name="name"
            value={userFirstName}
            placeholder="Nombre"
            onChange={(e) => _onUpdateFirstName(e.target.value)}
          />
        </div>
        <div className="profile_input_container">
          <div className="profile_title_label">
              Apellido
          </div>
          <input
            className="profile_input_item"
            type="text"
            name="name"
            value={userLastName}
            placeholder="Nombre"
            onChange={(e) => _onUpdateLastName(e.target.value)}
          />
        </div>
        <div className="profile_input_container">
          <div className="profile_title_label">
              Email
          </div>
          <input
            style={{border: "1px solid rgba(0,0,0,0)"}}
            className="profile_input_item"
            type="text"
            name="email"
            value={user?._email}
            placeholder="Email"
            disabled
          />
        </div>
        <div className="profile_input_container">
          <div className="profile_title_label">
              Tipo de Plan
          </div>
          <input
            style={{border: "1px solid rgba(0,0,0,0)"}}
            className="profile_input_item"
            type="text"
            name="plan"
            value={Plan[user?._userType as number]}
            placeholder="Plan"
            disabled
          />
        </div>
        <div className="profile_input_container">
          <div className="profile_title_label">
              Celular
          </div>
          <input
            className="profile_input_item"
            type="text"
            name="phone"
            value={userPhone}
            placeholder="Celular"
            onChange={(e) => _onUpdatePhone(e.target.value)}
          />
        </div>
        {
          // <div className="profile_input_container">
          // <div className="profile_title_label">
          //     Género
          // </div>
          // <select className='profile_input_item' value={selectedGender} onChange={handleChange}>
          //   <option value="" disabled>Seleccione una opción</option>
          //   <option value="masculino">Masculino</option>
          //   <option value="femenino">Femenino</option>
          //   <option value="no-responder">Prefiero no responder</option>
          // </select>
          // </div>
          // <div className='profile_input_container'>
          // <div className="profile_title_label">
          //   Fecha de Nacimiento
          // </div>
          // <div className='calendar_profile' onClick={() => setOpenBirthModal(!openBirthModal)}>
          //   {format((birthValue?.toString() || ''), 'yyyy-MM-dd')}
          //   <KeyboardArrowDownIcon />
          // </div>
          // {
          //   openBirthModal &&
          //   <div className='calendar_model_container'>
          //       <div className='calendar_model_container_inner'>
                
          //           <LocalizationProvider dateAdapter={AdapterDayjs}>
          //             <DateCalendar 
          //               value={birthValue} 
          //               onChange={handleDateChange}  // Set the value with Dayjs object
          //             />
          //           </LocalizationProvider>
                
                
          //       </div>
          //   </div>
          // }
          // </div>
        }
       
       
      </form>
      {
        
        <button className="signout_button_item right"
        onClick={() => {
          _onUpdateUser();
        }}>
          {
            !props.loading ?
              "Guardar" :
              <Box sx={{ display: 'flex'}}>
                <CircularProgress size="16px" style={{color: "gray"}} />
              </Box>
          }
        </button>
      }

      <div className='divider'></div>

      <div className='profile_title'>
        <p>Metricas Weightlifting</p>
      </div>
        <>
        <form className='profile_form_metrics'>
          <div className="profile_input_container">
            <div className="profile_title_label">
              Deadlift (lbs)
            </div>
            <input
              className="profile_input_item"
              style={{border: "1px solid rgba(0,0,0,0)"}}
              type="number"
              name="deadlift"
              value={metricsById?.deadlift ?? ''}
              placeholder="Deadlift"
              min={0}
              onChange={(e) => _onUpdateMetrics('deadlift', parseInt(e.target.value))}
            />
          </div>
          <div className="profile_input_container">
            <div className="profile_title_label">
              Back squat (lbs)
            </div>
            <input
              style={{border: "1px solid rgba(0,0,0,0)"}}
              className="profile_input_item"
              type="number"
              name="backsquat"
              value={metricsById?.backsquat ?? ''}
              placeholder="Back squat"
              min={0}
              onChange={(e) => _onUpdateMetrics('backsquat', parseInt(e.target.value))}
            />
          </div>
          <div className="profile_input_container">
            <div className="profile_title_label">
              Front squat (lbs)
            </div>
            <input
              className="profile_input_item"
              style={{border: "1px solid rgba(0,0,0,0)"}}
              type="number"
              name="frontsquat"
              value={metricsById?.frontsquat ?? ''}
              placeholder="Front squat"
              min={0}
              onChange={(e) => _onUpdateMetrics('frontsquat', parseInt(e.target.value))}
            />
          </div>
          <div className="profile_input_container">
            <div className="profile_title_label">
              Clean and jerk (lbs)
            </div>
            <input
              style={{border: "1px solid rgba(0,0,0,0)"}}
              className="profile_input_item"
              type="number"
              name="cleanandjerk"
              value={metricsById?.cleanandjerk ?? ''}
              placeholder="Clean and jerk"
              min={0}
              onChange={(e) => _onUpdateMetrics('cleanandjerk', parseInt(e.target.value))}
            />
          </div>
          <div className="profile_input_container">
            <div className="profile_title_label">
              Snatch (lbs)
            </div>
            <input
              className="profile_input_item"
              style={{border: "1px solid rgba(0,0,0,0)"}}
              type="number"
              name="snatch"
              value={metricsById?.snatch ?? ''}
              placeholder="Snatch"
              min={0}
              onChange={(e) => _onUpdateMetrics('snatch', parseInt(e.target.value))}
            />
          </div>
          <div className="profile_input_container">
            <div className="profile_title_label">
              Shoulder press (lbs)
            </div>
            <input
              style={{border: "1px solid rgba(0,0,0,0)"}}
              className="profile_input_item"
              type="number"
              name="shoulderpress"
              value={metricsById?.shoulderpress ?? ''}
              placeholder="Shoulder press"
              min={0}
              onChange={(e) => _onUpdateMetrics('shoulderpress', parseInt(e.target.value))}
            />
          </div>
          <div className="profile_input_container">
            <div className="profile_title_label">
              Bench press (lbs)
            </div>
            <input
              className="profile_input_item"
              style={{border: "1px solid rgba(0,0,0,0)"}}
              type="number"
              name="benchpress"
              value={metricsById?.benchpress ?? ''}
              placeholder="Bench press"
              min={0}
              onChange={(e) => _onUpdateMetrics('benchpress', parseInt(e.target.value))}
            />
          </div>
          
        </form>
        <button className="signout_button_item right"
            onClick={() => {
              _onSaveMetrics();
            }}>
              {
                !props.loadingSignOut ?
                  "Guardar" :
                  <Box sx={{ display: 'flex'}}>
                    <CircularProgress size="16px" style={{color: "gray"}} />
                  </Box>
              }
        </button></>
          
     
    </>
    
  );
};

export default withAuthAndVisibility(ProfileComponent);
