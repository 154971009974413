import { all, put, select, takeLatest } from "redux-saga/effects";
import { updateAllUsersAction, updateUserAction } from "./user";
import { AWSServices } from "../../../authentication/services/AuthServices";
import { Either, isRight } from "../../../utils/CheckResponseSide";
import { User } from "../../../authentication/entities/user";
import { ReSendEmailCodeInteractor } from "../../../authentication/useCases/resendEmailCodeInteractor";
import { SignOutInteractor } from "../../../authentication/useCases/signOutInteractor";
import { SendConfirmationInteractor } from "../../../authentication/useCases/sendConfirmationInteractor";
import { SignUpInteractor } from "../../../authentication/useCases/signUpInteractor";

import { GetCurrentUserInteractor, SignInInteractor } from "../../../authentication/useCases";
import { ScheduleServices } from "../../../schedule/services/ScheduleServices";
import { GetAllScheduleInteractor, GetUserSchedulesInteractor } from "../../../schedule/useCases";
import { updateAllScheduleAction, updateUserScheduleAction } from "./schedule";
import { updateLoadingAction, updateLoadingSignOutAction } from "./loading";
import { UpdateUserSchedulesInteractor } from "../../../schedule/useCases/updateUserScheduleInteractor";
import { GetAllUsersInteractor } from "../../../schedule/useCases/getAllUsersInteractor";
import { SendRecoveryPasswordCodeInteractor } from "../../../authentication/useCases/recoveryPasswordInteractor";
import { ShoppingServices } from "../../../shopping/services/ShoppingServices";
import { GetAllPurchaseInteractor } from "../../../shopping/useCases/getAllPurchaseInteractor";
import { CreatePurchaseInteractor } from "../../../shopping/useCases/createPurchaseInteractor";
import { updatePricesAction, updatePurchaseAction } from "./purchase";
import { PurchaseData } from "../../../shopping/entities";
import { DeletePurchaseInteractor } from "../../../shopping/useCases/deletePurchaseInteractor";
import { GetAllPricesInteractor } from "../../../shopping/useCases/getAllPricesInteractor";
import { UpdatePricesInteractor } from "../../../shopping/useCases/updatePricesInteractor";
import { updateMessageAction } from "./actionMessage";
import { UpdateUserProfileInfoInteractor } from "../../../schedule/useCases/UpdateUserProfileInfoInteractor";
import { MetricsServices } from "../../../metrics/services/MetricsServices";
import { GetAllMetricsInteractor } from "../../../metrics/useCases/getAllMetricsInteractor";
import { UpdateMetricsActionType, updateMetricsAction, updateMetricsByIdAction } from "./metrics";
import { GetMetricsByIdInteractor } from "../../../metrics/useCases/getMetricsByIdInteractor";
import { UpdateMetricsByIdInteractor } from "../../../metrics/useCases/updateMetricsByIdInteractor";

export const SIGN_IN = "user/saga/sign_in";
export const SIGN_OUT = "user/saga/sign_out";
export const SIGN_UP = "user/saga/sign_up";
export const SEND_EMAIL_CODE = "user/saga/send_email_code";

//recovery code for password
export const SEND_EMAIL_RECOVERY_CODE = "user/saga/send_email_recovery_code";
export const SEND_NEW_PASSWORD_RECOVERY = "user/saga/send_new_password_recovery";
//
export const GET_CURRENT_USER = "user/saga/get_current_user";
export const SEND_EMAIL_CONFIRMATION = "user/saga/send_email_confirmation";

export const GET_USER_SCHEDULE = "schedule/saga/get_user_schedule";
export const GET_ALL_SCHEDULE = "schedule/saga/get_all_schedule";
export const UPDATE_USER_SCHEDULE = "schedule/saga/update_user_schedule";

export const UPDATE_USER_INFO = "schedule/saga/update_user_info";
export const UPDATE_USER_PROFILE_INFO = "schedule/saga/update_user_profile_info";
export const UPDATE_USER_METRICS = "schedule/saga/update_user_metrics";

export const UPDATE_USER_LOCAL_INFO = "schedule/saga/update_user_local_info";

export const GET_ALL_USERS = "schedule/saga/get_all_users";

export const GET_ALL_PURCHASE = "schedule/saga/get_all_purchase";
export const GET_ALL_METRICS = "schedule/saga/get_all_metrics";
export const GET_METRICS_BY_ID = "schedule/saga/get_metrics_by_id";
export const CREATE_PURCHASE = "schedule/saga/create_purchase";
export const DELETE_PURCHASE = "schedule/saga/delete_purchase";
export const GET_ALL_PRICES = "schedule/saga/get_all_prices";
export const UPDATE_PRICES = "schedule/saga/update_prices";

export const UPDATE_ON_SCHEDULE = "schedule/saga/update_on_schedule";

export const LOADING = "user/saga/loading";

type UserSchedueleData = {
  id: number;
  table: string[];
  hours: string[];
  user: string;
};

interface SignInActionType {
    type: string;
    email: string;
    password: string
}

interface resendEmailCodeActionType {
    type: string;
    email: string;
}

interface resendEmailPasswordActionType {
  type: string;
  email: string;
  code: string;
  password: string;
}

interface SignOutActionType {
    type: string;
}

interface SendConfirmationActionType {
    type: string;
    email: string;
    code: string;
}

interface SignUpActionType {
    type: string;
    username: string,
    password: string,
    email: string,
    phone_number: string,
    given_name: string,
    family_name: string
  }


interface GetUserScheduleActionType {
    type: string;
    id: string;
}

interface GetAllScheduleActionType {
  type: string;
  date: Date;
}

interface GetAllUsersActionType {
  type: string;
}

interface GetAllPurchaseActionType {
  type: string;
}

interface GetAllPricesActionType {
  type: string;
}

interface CreatePurchaseActionType {
  type: string;
  data: PurchaseData
}

interface DeletePurchaseActionType {
  type: string;
  id: string
}

interface GetAllMetricsActionType {
  type: string;
}

interface GetMetricsByIdActionType {
  type: string;
  id: string;
}

interface UpdatePricesActionType {
  type: string;
  id: string,
  monthly: string,
  valley: string,
  quarterly: string,
  semester: string,
  ticket01: string,
  ticket04: string,
  ticket08: string,
  ticket12: string,
  ticket16: string,
}

interface UpdateUserScheduleActionType {
  type: string;
  data: UserSchedueleData[];
}

interface UpdateUserInfoActionType {
  type: string;
  id: string;
  active: boolean;
  activeDays: number;
  limiteDate: string;
  userType: string;
  startDate: string;
}

interface UpdateUserMetricsActionType {
  type: string,
  id: string,
  deadlift: number,
  backsquat: number,
  frontsquat: number,
  cleanandjerk: number,
  snatch: number,
  shoulderpress: number,
  benchpress: number,
}

interface UpdateUserProfileInfoActionType {
  type: string;
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string
}

interface UpdateLocalUserInfoActionType {
  type: string;
  id: string;
  active: boolean;
  activeDays: number;
  limitDate: Date;
  userType: number;
  startDate: Date;
}

interface IsLoadingActionType {
  type: string;
  state: boolean;
}

interface UpdateOnScheduleActionType {
  type: string;
  data: any;
}

export const getUserScheduleAction = (id: string): GetUserScheduleActionType => ({
  type: GET_USER_SCHEDULE,
  id,
});

export const getAllScheduleAction = (date: Date): GetAllScheduleActionType => ({
  type: GET_ALL_SCHEDULE,
  date,
});

export const getAllUsersAction = (): GetAllUsersActionType => ({
  type: GET_ALL_USERS,
});

export const getAllPurchaseAction = (): GetAllPurchaseActionType => ({
  type: GET_ALL_PURCHASE,
});

export const getAllMetricsAction = (): GetAllMetricsActionType => ({
  type: GET_ALL_METRICS,
});

export const getMetricsByIdAction = (id: string): GetMetricsByIdActionType => ({
  type: GET_METRICS_BY_ID,
  id,
});

export const getAllPricesAction = (): GetAllPricesActionType => ({
  type: GET_ALL_PRICES,
});

export const createPurchaseAction = (data: PurchaseData): CreatePurchaseActionType => ({
  type: CREATE_PURCHASE,
  data,
});

export const deletePurchaseAction = (id: string): DeletePurchaseActionType => ({
  type: DELETE_PURCHASE,
  id,
});

export const updateAllPricesAction = (
  id: string,
  monthly: string,
  valley: string,
  quarterly: string,
  semester: string,
  ticket01: string,
  ticket04: string,
  ticket08: string,
  ticket12: string,
  ticket16: string,
): UpdatePricesActionType => ({
  type: UPDATE_PRICES,
  id,
  monthly,
  valley,
  quarterly,
  semester,
  ticket01,
  ticket04,
  ticket08,
  ticket12,
  ticket16,
});

export const updateUserSchedulesAction = (data: UserSchedueleData[]): UpdateUserScheduleActionType => ({
  type: UPDATE_USER_SCHEDULE,
  data,
});

export const updateUserInfoAction = (
    id: string,
    active: boolean,
    activeDays: number,
    limiteDate: string,
    userType: string,
    startDate: string
  ): UpdateUserInfoActionType => ({
  type: UPDATE_USER_INFO,
  id,
  active,
  activeDays,
  limiteDate,
  userType,
  startDate
});

export const updateUserProfileInfoAction = (
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string
): UpdateUserProfileInfoActionType => ({
    type: UPDATE_USER_PROFILE_INFO,
    id,
    firstName,
    lastName,
    email,
    phoneNumber
});

export const updateUserMetricsAction = (
  id: string,
  deadlift: number,
  backsquat: number,
  frontsquat: number,
  cleanandjerk: number,
  snatch: number,
  shoulderpress: number,
  benchpress: number,
): UpdateUserMetricsActionType => ({
    type: UPDATE_USER_METRICS,
    id,
    deadlift,
    backsquat,
    frontsquat,
    cleanandjerk,
    snatch,
    shoulderpress,
    benchpress
});

export const updateOnSchedulesAction = (data: any): UpdateOnScheduleActionType => ({
  type: UPDATE_ON_SCHEDULE,
  data
});

export const updateLocalUserInfoAction = (
  id: string,
  active: boolean,
  activeDays: number,
  limitDate: Date,
  userType: number,
  startDate: Date,
): UpdateLocalUserInfoActionType => ({
  type: UPDATE_USER_LOCAL_INFO,
  id,
  active,
  activeDays,
  limitDate,
  userType,
  startDate
});

export const signInAction = (email: string, password: string): SignInActionType => ({
  type: SIGN_IN,
  email,
  password
});

export const signOutUserAction = (): SignOutActionType => ({
    type: SIGN_OUT
});

export const sendEmailConfirmationAction = (email: string, code: string): SendConfirmationActionType => ({
    type: SEND_EMAIL_CONFIRMATION,
    email,
    code
});

export const sendNewPasswordRecoveryAction = (email: string, code: string, password: string): resendEmailPasswordActionType => ({
  type: SEND_NEW_PASSWORD_RECOVERY,
  email,
  code,
  password
});

export const resendEmailCodeAction = (email: string): resendEmailCodeActionType => ({
    type: SEND_EMAIL_CODE,
    email,
  });

export const sendRevoveryCodeAction = (email: string): resendEmailCodeActionType => ({
  type: SEND_EMAIL_RECOVERY_CODE,
  email,
});

export const isLoadingAction = (state: boolean): IsLoadingActionType => ({
  type: LOADING,
  state,
});

export const signUpAction = (
    username: string,
    password: string,
    email: string,
    phone_number: string,
    given_name: string,
    family_name: string
): SignUpActionType => ({
    type: SIGN_UP,
    username,
    password,
    email,
    phone_number,
    given_name,
    family_name
  });

export const getCurrentUserAction = () => ({
    type: GET_CURRENT_USER,
});

function* signInSaga(action: SignInActionType) {
  const { email, password } = action;
  try {
    const service = new AWSServices();
    const interactor = new SignInInteractor(service);

    yield put(updateLoadingAction(true));

    const user: Either<Error, any> = yield interactor.signIn(email, password);
    if(isRight(user)){
      if (user.value._userStatus === "activeUser") {
        yield getCurrentUserSaga();
        yield put(updateLoadingAction(false));
      } else {
        yield put(updateUserAction("user/update", user.value));
        yield put(updateLoadingAction(false));
      }
    }
    
  } catch (error) {
    console.error(error);
  }
}

function* resendEmailCodeSaga(action: resendEmailCodeActionType) {
    const { email } = action;
    try {
      const service = new AWSServices();
      const interactor = new ReSendEmailCodeInteractor(service);

      yield put(updateLoadingAction(true));
  
      const user: Either<Error, User> = yield interactor.resendCode(email);
      yield put(updateUserAction("user/update", user.value as User));

      yield put(updateLoadingAction(false));
      
    } catch (error) {
      console.error(error);
    }
  }

  //recovery code for password change
  function* sendEmailRecoveryCodeSaga(action: resendEmailCodeActionType) {
    const { email } = action;
    try {
      const service = new AWSServices();
      const interactor = new SendRecoveryPasswordCodeInteractor(service);

      yield put(updateLoadingAction(true));
  
      const user: Either<Error, User> = yield interactor.sendCode(email);
      yield put(updateUserAction("user/update", user.value as User));

      yield put(updateLoadingAction(false));
      
    } catch (error) {
      console.error(error);
    }
  }

  function* sendRecoveryPasswordSaga(action: resendEmailPasswordActionType) {
    const { email, code, password } = action;
    try {
      const service = new AWSServices();
      const interactor = new SendRecoveryPasswordCodeInteractor(service);

      yield put(updateLoadingAction(true));
  
      const user: Either<Error, User> = yield interactor.sendNewPassword(email, code, password);
      yield put(updateUserAction("user/update", user.value as User));

      yield put(updateLoadingAction(false));
      
    } catch (error) {
      console.error(error);
    }
  }

function* signOutSaga() {
    try {
      const service = new AWSServices();
      const interactor = new SignOutInteractor(service);

      yield put(updateLoadingSignOutAction(true));
  
      const user: Either<Error, User> = yield interactor.signOut();
      yield put(updateUserAction("user/update", user.value as User));

      yield put(updateLoadingSignOutAction(false));
      
    } catch (error) {
      console.error(error);
    }
  }

function* sendEmailConfirmationSaga(action: SendConfirmationActionType) {
    const { 
        email,
        code
    } = action;
    try {
      const service = new AWSServices();
      const interactor = new SendConfirmationInteractor(service);

      yield put(updateLoadingAction(true));
  
      const user: Either<Error, User> = yield interactor.sendCode(email, code);
      
      //const state: ReturnType<typeof userSelector> = yield select(userSelector);
      if (isRight(user)) {
          yield put(updateUserAction("user/update", user.value as User));
      } else {
          yield put(updateUserAction("user/update", null));
      }

      yield put(updateLoadingAction(false));
      
    } catch (error) {
      console.error(error);
    }
  }

function* signUpSaga(action: SignUpActionType) {
    const { username,
        password,
        email,
        phone_number,
        given_name,
        family_name } = action;
    try {
      const service = new AWSServices();
      const interactor = new SignUpInteractor(service);

      yield put(updateLoadingAction(true));
  
      const user: Either<Error, User> = yield interactor.signUp(
        username,
        password,
        email,
        phone_number,
        given_name,
        family_name 
      );
      //const state: ReturnType<typeof userSelector> = yield select(userSelector);
      if (isRight(user)) {
          yield put(updateUserAction("user/update", user.value as User));
      } else {
          yield put(updateUserAction("user/update", null));
      }

      yield put(updateLoadingAction(false));
      
    } catch (error) {
      console.error(error);
    }
  }
  

function* getCurrentUserSaga() {
    try {
        const service = new AWSServices();
        const interactor = new GetCurrentUserInteractor(service);

        yield put(updateLoadingAction(true));

        const user: Either<Error, User> = yield interactor.getCurrentActiveUser();
        
        if (isRight(user)) {
          const userCasted = user.value as User;
          
          const service = new ScheduleServices();
          const interactor = new GetUserSchedulesInteractor(service);
          if (userCasted._email !== "") {

            const response: Either<Error, any> = yield interactor.getUserSchedules(userCasted._email);

            if (response !== undefined && isRight(response)) {
              yield put(updateUserScheduleAction(JSON.parse(response.value._schedule)));
              yield put(updateUserAction("user/update", response.value));
              yield put(updateLoadingAction(false));
            }
            
          } else {
            yield put(updateUserAction("user/update", userCasted));
            yield put(updateLoadingAction(false));
          }
          
        }
      
    } catch (error) {
      console.error(error);
    }
  }



function* getAllScheduleSaga(action: GetAllScheduleActionType){
  const { date } = action;

  try {
    const service = new ScheduleServices();
    const interactor = new GetAllScheduleInteractor(service);
  
    const resultingDate: Either<Error, any> = yield interactor.getAllSchedule(date);
    
    if (isRight(resultingDate)) {
      const responseCAsted = resultingDate.value.listSchedules.items;
      yield put(updateAllScheduleAction(responseCAsted));
    }

  } catch (error) {
      console.error(error);
  }
}

function* getAllUsersSaga(){

  try {
    const service = new ScheduleServices();
    const interactor = new GetAllUsersInteractor(service);
  
    const resultingUsers: Either<Error, any> = yield interactor.getAllUsers();
    
    if (isRight(resultingUsers)) {
      const responseCasted = resultingUsers.value;
      yield put(updateAllUsersAction(responseCasted));
    } else {
      getAllUsersSaga()
    }
    
  } catch (error) {
      console.error(error);
  }
}

function* getAllPurchaseSaga(){

  try {
    const service = new ShoppingServices();
    const interactor = new GetAllPurchaseInteractor(service);
    const resultingPurchase: Either<Error, any> = yield interactor.getAllPurchase();
    if (isRight(resultingPurchase)) {
      yield put(updatePurchaseAction(resultingPurchase.value));
    }
    
  } catch (error) {
      console.error(error);
  }
}

function* getAllMetricsSaga(){

  try {
    const service = new MetricsServices();
    const interactor = new GetAllMetricsInteractor(service);
    const resultingMetrics: Either<Error, any> = yield interactor.getAllMetrics();
    
    
    if (isRight(resultingMetrics)) {
      yield put(updateMetricsAction(resultingMetrics.value));
    }
    
  } catch (error) {
      console.error(error);
  }
}

function* getMetricsByIdSaga(action: GetMetricsByIdActionType){

  const { id } = action;
  
  try {
    const service = new MetricsServices();
    const interactor = new GetMetricsByIdInteractor(service);
    const resultingMetrics: Either<Error, any> = yield interactor.getMetricsById(id);
    
    if (isRight(resultingMetrics)) {
      yield put(updateMetricsByIdAction(resultingMetrics.value));
    }
    
  } catch (error) {
      console.error(error);
  }
}

function* getAllPricesSaga(){

  try {
    const service = new ShoppingServices();
    const interactor = new GetAllPricesInteractor(service);
    const resultingPrices: Either<Error, any> = yield interactor.getAllPrices();
    
    if (isRight(resultingPrices)) {
      yield put(updatePricesAction(resultingPrices.value));
    }
    
  } catch (error) {
      console.error(error);
  }
}

function* insertPurchaseInfoSaga(action: CreatePurchaseActionType){

  const {
    data
  } = action;
 
  try {
    const service = new ShoppingServices();
    const interactor = new CreatePurchaseInteractor(service);

    yield put(updateLoadingAction(true));

    const resultingPurchase: Either<Error, any> = yield interactor.createPurchase(data);

    if(isRight(resultingPurchase)){
        yield put(updateMessageAction("Tu compra ha sido enviada, avisamos para activar tu cuenta!!"));
    } else {
        yield put(updateMessageAction("Hubo un error en tu compra, vuelve a intentarlo"));
    }

    yield put(updateLoadingAction(false));

  } catch (error) {
      console.error(error);
      yield put(updateMessageAction("Hubo un error en tu compra, vuelve a intentarlo"));
  }
}

function* deletePurchaseInfoSaga(action: DeletePurchaseActionType){

  const {
    id
  } = action;
 
  try {
    const service = new ShoppingServices();
    const interactor = new DeletePurchaseInteractor(service);

    yield interactor.deletePurchase(id);
    yield getAllPurchaseSaga();

  } catch (error) {
      console.error(error);
  }
}

function* updatePricesInfoSaga(action: UpdatePricesActionType){
  const { 
    id,
    monthly,
    valley,
    quarterly,
    semester,
    ticket01,
    ticket04,
    ticket08,
    ticket12,
    ticket16
   } = action;

  try {
    const service = new ShoppingServices();
    const interactor = new UpdatePricesInteractor(service);

    yield put(updateLoadingSignOutAction(true));
  
    yield interactor.updatePricesInfo(
      id,
      monthly,
      valley,
      quarterly,
      semester,
      ticket01,
      ticket04,
      ticket08,
      ticket12,
      ticket16
    );

    yield getAllPricesSaga();

    yield put(updateLoadingSignOutAction(false));

  } catch (error) {
      console.error(error);
  }
}

function* insertNewScheduleSaga(action: UpdateOnScheduleActionType): Generator<any, void, any> {
  const { data } = action;
  

  try {
    const dataToInsert = yield select((state) => state);

    const checkOldSchedule = dataToInsert.allSchedule.filter((schedule: any) => {
      if(schedule.id.toString() !== data.id.toString()){
        return true;
      }
    });
    checkOldSchedule.push({...data});
    yield put(updateAllScheduleAction(checkOldSchedule));
    
  } catch (error) {
      console.error(error);
  }
}

function* insertUserInfoSaga(action: UpdateLocalUserInfoActionType){
  const {
  id,
  active,
  activeDays,
  limitDate,
  userType,
  startDate
} = action;

  try {

    yield put(updateLoadingAction(true));
  
    const user = {
      _email: id,
      _active: active,
      _activeDays: activeDays,
      _limitDate: limitDate,
      _userType: userType,
      _startDate: startDate
    }
    yield put(updateUserAction("user/update_fields", user));
    yield put(updateLoadingAction(false));

  } catch (error) {
      console.error(error);
  }
}

function* updateUserScheduleSaga(action: UpdateUserScheduleActionType){
  const { data } = action;

  try {
    const service = new ScheduleServices();
    const interactor = new UpdateUserSchedulesInteractor(service);

    yield put(updateLoadingAction(true));
    yield put(updateMessageAction(null));
  
    const response : Either<Error, any> =  yield interactor.updateUserSchedule(data);
    if (isRight(response)) {
      yield put(updateMessageAction(response.value['action'] + ' with ' + response.value['status']));
    }
    
    yield getCurrentUserSaga();

    yield put(updateLoadingAction(false));

  } catch (error) {
      console.error(error);
  }
}

function* updateUserInfoSaga(action: UpdateUserInfoActionType){
  const { id, active, activeDays, limiteDate, userType, startDate } = action;

  try {
    const service = new ScheduleServices();
    const interactor = new UpdateUserSchedulesInteractor(service);

    yield put(updateLoadingAction(true));
  
    yield interactor.updateUserInfo(id, active, activeDays, limiteDate, userType, startDate);

    yield put(updateLoadingAction(false));

  } catch (error) {
      console.error(error);
  }
}

function* updateUserMetricsSaga(action: UpdateUserMetricsActionType){
  const { 
    id,
    deadlift,
    backsquat,
    frontsquat,
    cleanandjerk,
    snatch,
    shoulderpress,
    benchpress
   } = action;

  try {
    const service = new MetricsServices();
    const interactor = new UpdateMetricsByIdInteractor(service);

    yield put(updateLoadingSignOutAction(true));
  
    const result: Either<Error, any> = yield interactor.updateMetricsById(
      id,
      deadlift,
      backsquat,
      frontsquat,
      cleanandjerk,
      snatch,
      shoulderpress,
      benchpress
    );

    if (isRight(result)) {
      yield put(updateMetricsByIdAction({
        id,
        deadlift,
        backsquat,
        frontsquat,
        cleanandjerk,
        snatch,
        shoulderpress,
        benchpress
      }));
    }
    
    yield put(updateLoadingSignOutAction(false));

  } catch (error) {
      console.error(error);
  }
}

function* updateUserProfileInfoSaga(action: UpdateUserProfileInfoActionType){
  const { id, firstName, lastName, email, phoneNumber } = action;

  try {
    const service = new ScheduleServices();
    const interactor = new UpdateUserProfileInfoInteractor(service);

    yield put(updateLoadingAction(true));
  
    yield interactor.updateUserProfileInfo(id, firstName, lastName, email, phoneNumber);
    yield getCurrentUserSaga();

    yield put(updateLoadingAction(false));

  } catch (error) {
      console.error(error);
  }
}


function* isLoadingSaga(action: IsLoadingActionType) {
  const { state } = action;
  
  try {
      yield put(updateLoadingAction(state));
  } catch (error) {
      console.error(error);
      
  }
}

export function* rootSaga() {
  yield all([
        takeLatest(SIGN_IN, signInSaga),
        takeLatest(GET_CURRENT_USER, getCurrentUserSaga),
        takeLatest(SIGN_UP, signUpSaga),
        takeLatest(SEND_EMAIL_CONFIRMATION, sendEmailConfirmationSaga),
        takeLatest(SIGN_OUT, signOutSaga),
        takeLatest(SEND_EMAIL_CODE, resendEmailCodeSaga),
        takeLatest(LOADING, isLoadingSaga),
        takeLatest(GET_ALL_SCHEDULE, getAllScheduleSaga),
        takeLatest(UPDATE_USER_SCHEDULE, updateUserScheduleSaga),
        takeLatest(UPDATE_ON_SCHEDULE, insertNewScheduleSaga),
        takeLatest(GET_ALL_USERS, getAllUsersSaga),
        takeLatest(UPDATE_USER_INFO, updateUserInfoSaga),
        takeLatest(UPDATE_USER_LOCAL_INFO, insertUserInfoSaga),
        takeLatest(SEND_EMAIL_RECOVERY_CODE, sendEmailRecoveryCodeSaga),
        takeLatest(SEND_NEW_PASSWORD_RECOVERY, sendRecoveryPasswordSaga),
        takeLatest(GET_ALL_PURCHASE, getAllPurchaseSaga),
        takeLatest(CREATE_PURCHASE, insertPurchaseInfoSaga),
        takeLatest(DELETE_PURCHASE, deletePurchaseInfoSaga),
        takeLatest(GET_ALL_PRICES, getAllPricesSaga),
        takeLatest(UPDATE_PRICES, updatePricesInfoSaga),
        takeLatest(UPDATE_USER_PROFILE_INFO, updateUserProfileInfoSaga),
        takeLatest(GET_ALL_METRICS, getAllMetricsSaga),
        takeLatest(GET_METRICS_BY_ID, getMetricsByIdSaga),
        takeLatest(UPDATE_USER_METRICS, updateUserMetricsSaga)
    ]);
}