import React, { useState, ChangeEvent, FormEvent } from "react";
import HeaderComponent from "../Header/HeaderComponent";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import './SignUpComponentStyles.css';
import { emailValidation, nameValidation, passwordValidation, phoneValidation } from "../../utils/validation";
import { Props } from "../AppPresenter";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface PropsType {
  onClick: (
    username: string,
    password: string,
    email: string,
    phone_number: string,
    given_name: string,
    family_name: string
  ) => void;
  props: Props
}

const SignUpComponent: React.FC<PropsType> = ({ onClick, props }) => {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    username: "",
    phoneNumber: "",
    givenName: "",
    familyName: ""
  });

  const [ errorMessages, setErrorMessages ] = useState({
    "email": "",
    "password": "",
    "userName": "",
    "phone": "",
    "name": "",
    "lastName": ""
  })

  const [loading, setLoading] = useState(props.loading);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isEmailValid = emailValidation(formData.email);
    const isPhoneValid = phoneValidation(formData.phoneNumber);
    const isPasswordValid = passwordValidation(formData.password);
    const isUserNameValid = emailValidation(formData.username);
    const isNameValidation = nameValidation(formData.givenName);
    const isNameLastName = nameValidation(formData.familyName);
  
        if (
          isEmailValid === "" &&
          isPhoneValid === "" &&
          isPasswordValid === "" &&
          isUserNameValid === "" &&
          isNameValidation === "" &&
          isNameLastName === ""
        ) {
          setErrorMessages({
            "email": "",
            "password": "",
            "userName": "",
            "phone": "",
            "name": "",
            "lastName": ""
          });
          onClick(
            formData.username,
            formData.password,
            formData.email,
            formData.phoneNumber,
            formData.givenName,
            formData.familyName
          );
          
        } else {
            setErrorMessages({
              "email": isEmailValid,
              "password": isPasswordValid,
              "userName": isUserNameValid,
              "phone": isPhoneValid,
              "name": isNameValidation,
              "lastName": isNameLastName
            })
        }
  };

  React.useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  React.useEffect(() => {
    console.log(props.user);
    if (props.user?._userStatus === "userNeedConfirmation") {
      navigate('/confirmation');
    }
  }, [props.user?._userStatus]);


  return (
    <>
    <HeaderComponent />
    <div className="signout_container">
      <div className="signout_sub_container">
      <p className="signout_title">!Estamos felices de que quieras unirte a esta familia! Regálanos algunos datos y  completa tu registro.</p>
      <form onSubmit={handleSubmit}>
        <div className="signout_input_container">
          <div className="signout_title_label">
              Email
          </div>
          <input
            className="signout_input_item"
            type="text"
            name="email"
            value={formData.email}
            placeholder="email"
            onChange={handleChange}
          />
          <p className="error_message_registration">{errorMessages["email"] !== "" ? errorMessages["email"]: ""}</p>
        </div>
        <div className="signout_input_container">
          <div className="signout_title_label">
            Password
          </div>
          <input
            className="signout_input_item password"
            type={passwordVisible ? 'text' : 'password'}
            name="password"
            value={formData.password}
            placeholder="contraseña"
            onChange={handleChange}
          />
          <div
            onClick={togglePasswordVisibility}
            className="password_toggle_btn"
          >
            {passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </div>
          <p className="error_message_registration">{errorMessages["password"] !== "" ? errorMessages["password"]: ""}</p>
        </div>
        <div className="signout_input_container">
          <div className="signout_title_label">
            Username
          </div>
          <input
            className="signout_input_item"
            type="text"
            name="username"
            value={formData.username}
            placeholder="email"
            onChange={handleChange}
          />
           <p className="error_message_registration">{errorMessages["userName"] !== "" ? errorMessages["userName"]: ""}</p>
        </div>
        <div className="signout_input_container">
          <div className="signout_title_label">
            Celular
          </div>
          <input
            className="signout_input_item"
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            placeholder="celular"
            onChange={handleChange}
          />
          <p className="error_message_registration">{errorMessages["phone"] !== "" ? errorMessages["phone"]: ""}</p>
        </div>
        <div className="signout_input_container">
          <div className="signout_title_label">
            Nombre
          </div>
          <input
            className="signout_input_item"
            type="text"
            name="givenName"
            value={formData.givenName}
            placeholder="nombre"
            onChange={handleChange}
          />
          <p className="error_message_registration">{errorMessages["name"] !== "" ? errorMessages["name"]: ""}</p>
        </div>
        <div className="signout_input_container">
          <div className="signout_title_label">
            Apellido
          </div>
          <input
            className="signout_input_item"
            type="text"
            name="familyName"
            value={formData.familyName}
            placeholder="apellido"
            onChange={handleChange}
          />
          <p className="error_message_registration">{errorMessages["lastName"] !== "" ? errorMessages["lastName"]: ""}</p>
        </div>
        <div className="signin_registration_container">
            <button className="signout_button_item" type="submit">
              {
                !loading ? "Registrarme" : 
                <Box sx={{ display: 'flex'}}>
                  <CircularProgress size="16px" style={{color: "gray"}} />
                </Box>
              }
            </button>
        </div>
        
      </form>
      </div>
    </div>
    </>
    
  
  );
};

export default SignUpComponent;
