import { User } from "../../authentication/entities";
import { Either } from "../../utils/CheckResponseSide";
import { Purchase } from "../entities/shopping";

export interface GetAllPricesService {
  getAllPricesImpl: () => Promise<Either<Error, Purchase[]>> ;
}

export class GetAllPricesInteractor {
  getAllPricesService: GetAllPricesService;

  constructor(getAllPricesService: GetAllPricesService) {
    this.getAllPricesService = getAllPricesService;
  }

  async getAllPrices(): Promise<Either<Error, Purchase[]>> {
    const response = await this.getAllPricesService.getAllPricesImpl();
    return response;
  }
}