import { Either, left, right } from '../../utils/CheckResponseSide';
import { Purchase } from '../entities';
import { CreatePurchaseService } from '../useCases/createPurchaseInteractor';
import { DeletePurchaseService } from '../useCases/deletePurchaseInteractor';
import { GetAllPricesService } from '../useCases/getAllPricesInteractor';
import { GetAllPurchaseService } from '../useCases/getAllPurchaseInteractor';
import { UpdatePricesService } from '../useCases/updatePricesInteractor';


const GRAPHQL_ENDPOINT = "https://3ue6aegggvedndfjtxez3hjnlq.appsync-api.us-east-1.amazonaws.com/graphql";
const GRAPHQL_API_KEY = "da2-va6z7bp4p5cp7k5ambqjzxmngi";
const response: any = {};

export class ShoppingServices implements 
    GetAllPurchaseService,
    CreatePurchaseService,
    DeletePurchaseService,
    GetAllPricesService,
    UpdatePricesService
  {

    async createPurchaseImpl(input: any) : Promise<any> {

        const queryPurchaseCreate = `
            mutation CreateShoppingState($input: CreateShoppingStateInput!) {
                createShoppingState(input: $input) {
                    id
                    email
                    activeDays
                    limitDate
                    startDate
                    firstName
                    lastName
                    plan
                }
            }
        `;

        const variables = { input: input };
        
        const options = {
            method: "POST",
            headers: {
              "x-api-key": GRAPHQL_API_KEY,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ query: queryPurchaseCreate, variables }),
        };

        try {
            const res = await fetch(GRAPHQL_ENDPOINT, options);
            if (res.ok) {
              return right(res);
            } else {
              return left(res);
            }
            
        } catch (error) {
    
            console.log(error, "error");
            response.statusCode = 400;
            response.body = {
              errors: [
                {
                  message: error,
                  stack: error,
                },
              ],
            };
            left(response);
          }
    }

    async deletePurchaseImpl(id: string) : Promise<any> {
      
      const queryPurchaseDelete = `
          mutation deleteShoppingState($input: DeleteShoppingStateInput!) {
            deleteShoppingState(input: $input) {
                  id
              }
          }
      `;

      const variables = { input: {id} };
      
      const options = {
          method: "POST",
          headers: {
            "x-api-key": GRAPHQL_API_KEY,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ query: queryPurchaseDelete, variables }),
      };

      try {
          const res = await fetch(GRAPHQL_ENDPOINT, options);
          
      } catch (error) {
  
          console.log(error, "error");
          response.statusCode = 400;
          response.body = {
            errors: [
              {
                message: error,
                stack: error,
              },
            ],
          };
          left(response);
        }
  }

    async getAllPurchaseImpl(): Promise<any> {
        const query = /* GraphQL */ `
        query MyQuery($nextToken: String) {
            listShoppingStates(limit: 1000, nextToken: $nextToken) {
                nextToken
                items {
                  activeDays
                  createdAt
                  email
                  firstName
                  id
                  lastName
                  limitDate
                  startDate
                  plan
                  updatedAt
                }
              }
        }
        `;
    
        const options = {
          method: "POST",
          headers: {
            "x-api-key": GRAPHQL_API_KEY,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ query: query }),
        };
        try {
            const res = await fetch(GRAPHQL_ENDPOINT, options);
            if (!res.body) {
                console.error("No response body available");
                return;
            }
      
            const reader = res.body.getReader();
            let chunks = [];
            let totalLength = 0;
      
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                chunks.push(value);
                totalLength += value.length;
            }
      
            const concatenated = new Uint8Array(totalLength);
            let offset = 0;
            for (let chunk of chunks) {
                concatenated.set(chunk, offset);
                offset += chunk.length;
            }
      
            const result = new TextDecoder().decode(concatenated);
      
            const usersResult = JSON.parse(result).data.listShoppingStates.items;
            return right(usersResult);
          
        } catch (error) {
    
          console.log(error, "error");
          response.statusCode = 400;
          response.body = {
            errors: [
              {
                message: error,
                stack: error,
              },
            ],
          };
          left(response);
        }
    }

    async getAllPricesImpl(): Promise<any> {
      const query = /* GraphQL */ `
      query MyQuery {
        listPrices {
          nextToken
          items {
            ticket16
            ticket12
            ticket08
            ticket04
            ticket01
            semester
            quarterly
            monthly
            valley
            id
          }
        }
      }
      `;
  
      const options = {
        method: "POST",
        headers: {
          "x-api-key": GRAPHQL_API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ query: query }),
      };
      try {
          const res = await fetch(GRAPHQL_ENDPOINT, options);
          
          if (!res.body) {
              console.error("No response body available");
              return;
          }
    
          const reader = await res.body.getReader();
          let chunks = [];
          let totalLength = 0;
    
          while (true) {
              const { done, value } = await reader.read();
              if (done) {
                  break;
              }
              chunks.push(value);
              totalLength += value.length;
          }
    
          const concatenated = new Uint8Array(totalLength);
          let offset = 0;
          for (let chunk of chunks) {
              concatenated.set(chunk, offset);
              offset += chunk.length;
          }
    
          const result = new TextDecoder().decode(concatenated);
          const usersResult = JSON.parse(result).data.listPrices.items;
          return right(usersResult);
        
      } catch (error) {
  
        console.log(error, "error");
        response.statusCode = 400;
        response.body = {
          errors: [
            {
              message: error,
              stack: error,
            },
          ],
        };
        left(response);
      }
  }

  async updatePricesInfoImpl(
    id: string,
    monthly: string,
    valley: string,
    quarterly: string,
    semester: string,
    ticket01: string,
    ticket04: string,
    ticket08: string,
    ticket12: string,
    ticket16: string,
  ): Promise<any> {

    const query = /* GraphQL */ `
      mutation MyMutation($id: ID!, $monthly: String, $valley: String, $quarterly: String, $semester: String, $ticket01: String, $ticket04: String, $ticket08: String, $ticket12: String, $ticket16: String) {
        updatePrices(input: { id: $id, monthly: $monthly, valley: $valley, quarterly: $quarterly, semester: $semester, ticket01: $ticket01, ticket04: $ticket04, ticket08: $ticket08, ticket12: $ticket12, ticket16: $ticket16 }) {
          id
          monthly
          valley
          quarterly
          semester
          ticket01
          ticket04
          ticket08
          ticket12
          ticket16
        }
      }
    `;

    const variables = {
      id,
      monthly,
      valley,
      quarterly,
      semester,
      ticket01,
      ticket04,
      ticket08,
      ticket12,
      ticket16
    };

    const options = {
        method: "POST",
        headers: {
            "x-api-key": GRAPHQL_API_KEY,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ query, variables }),
    };

    try {
      const res = await fetch(GRAPHQL_ENDPOINT, options);
      await res.json();
    } catch (error) {
      console.log(error, "error");
    }

}
}
