import { UserSchedueleData } from "../components/AppPresenter";

export const checkingUser = (value: any) =>{
    const limitDate = value._limitDate as unknown as string;
      const limiteUserDate = new Date(`${limitDate}T00:00:00`);
      limiteUserDate.setHours(0, 0, 0, 0);

      const dataScheduletoParse = value._schedule as unknown as string || "[]";
      
      const parsedData = JSON.parse(dataScheduletoParse);
      const dataArray: UserSchedueleData[] = Object.values(parsedData);
      const daysFromStartedDay = dataArray.filter(item => {
        const startedDay = new Date(value._startDate || new Date()).setHours(0,0,0,0);
        if(item.id >= startedDay && item.hours.length > 0){
            return true;
        }
      }).sort((a, b) => new Date(a.id).setHours(0,0,0,0) - new Date(b.id).setHours(0,0,0,0));

      let validation = true;

      if (limiteUserDate.setHours(0,0,0,0) < new Date().setHours(0,0,0,0) && value._userType.toString() !== "1") {
        validation = false;
      }
      const currentDate = new Date();
      const twoDaysLater = new Date(currentDate);
      twoDaysLater.setDate(currentDate.getDate() + 0);

      if (daysFromStartedDay.length >= value?._activeDays && new Date(daysFromStartedDay[daysFromStartedDay.length - 1]?.id).setHours(0,0,0,0) < twoDaysLater.setHours(0,0,0,0) && value._userType.toString() !== "1") {
        validation = false;
      }

      return validation;
    }