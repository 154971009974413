import { Metrics } from "../../../metrics/entities";
import { StateType, initialState } from "./state";

const UPDATE_METRICS = "actionMetrics/update";
const UPDATE_METRICS_BY_ID = "actionMetricsById/update";

type StateSliceMetrics = StateType["allMetrics"];
type StateSliceMetricsById = StateType["metricsById"];

export interface UpdateMetricsActionType { 
  type: typeof UPDATE_METRICS,
  metrics: Metrics[] | null,
}

export interface UpdateMetricsByIdActionType { 
  type: typeof UPDATE_METRICS_BY_ID,
  metrics: any | null,
}

export const metricsSelector = (state: StateType): StateSliceMetrics => state.allMetrics;

export const updateMetricsAction = (metrics: Metrics[] | null): UpdateMetricsActionType => ({
  type: UPDATE_METRICS,
  metrics,
});

export const metricsByIdSelector = (state: StateType): StateSliceMetricsById => state.metricsById;

export const updateMetricsByIdAction = (metrics: any | null): UpdateMetricsByIdActionType => ({
  type: UPDATE_METRICS_BY_ID,
  metrics,
});

const updateMetricsHandler = (
  state: StateSliceMetrics,
  action: UpdateMetricsActionType
): StateSliceMetrics => {
  return action.metrics;
};


export const metricsReduce = (
  state: StateSliceMetrics = initialState.allMetrics,
  action: UpdateMetricsActionType
): StateSliceMetrics => {
  switch (action.type) {
    case UPDATE_METRICS:
      return updateMetricsHandler(state, action);
    default:
      return state;
  }
};

const updateMetricsByIdHandler = (
  state: StateSliceMetricsById,
  action: UpdateMetricsByIdActionType
): StateSliceMetricsById => {
  return action.metrics;
};


export const metricsByIdReduce = (
  state: StateSliceMetricsById = initialState.metricsById,
  action: UpdateMetricsByIdActionType
): StateSliceMetricsById => {
  switch (action.type) {
    case UPDATE_METRICS_BY_ID:
      return updateMetricsByIdHandler(state, action);
    default:
      return state;
  }
};
