import { Either } from "../../utils/CheckResponseSide";
import { User } from "../entities";

export interface SignOutService {
  signOutUser: () => Promise<Either<Error, User>> ;
}

export class SignOutInteractor {
  signOutService: SignOutService;

  constructor(signOutService: SignOutService) {
    this.signOutService = signOutService;
  }

  async signOut(): Promise<Either<Error, User>> {
    const response = await this.signOutService.signOutUser();
    return response;
  }
}