import * as React from "react";
import { connect } from "react-redux";
import SignInComponent  from "./SignIn/SignInComponent";
import ScheduleComponent from "./Schedule/ScheduleComponent";
import { Route, Routes, useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { LoadingComponent } from "./Loading/LoadingComponent";
import SignUpComponent from "./SignUp/SignUpComponent";
import UserConfirmationComponent from "./UserConfirmationComponent";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StateType,
    getAllScheduleAction,
    getCurrentUserAction,
    getUserScheduleAction,
    loadingSelector,
    resendEmailCodeAction,
    userScheduleSelector,
    sendEmailConfirmationAction,
    signInAction,
    signOutUserAction,
    signUpAction,
    userSelector, 
    allScheduleSelector,
    updateUserSchedulesAction,
    loadingSignOutSelector,
    updateOnSchedulesAction,
    getAllUsersAction,
    updateUserInfoAction,
    updateLocalUserInfoAction,
    sendRevoveryCodeAction,
    sendNewPasswordRecoveryAction,
    allUsersSelector,
    allPurchaseSelector,
    getAllPurchaseAction,
    createPurchaseAction,
    deletePurchaseAction,
    getAllPricesAction,
    allPricesSelector,
    updateAllPricesAction,
    updateUserProfileInfoAction,
    getAllMetricsAction,
    getMetricsByIdAction,
    updateUserMetricsAction,
} from "../app/store/adapters";
import { User } from "../app/authentication/entities";
import { Schedule } from "../app/schedule/entities";

import { generateClient } from 'aws-amplify/api';
import DashBoardComponent from "./DashBoard/DashBoardComponent";
import { onUpdateUser } from "../graphql/subscriptionsOnUpdate";
import SendRecoveryConfirmationComponent from "./SendRecoveryConfirmationComponent";
import RecoveryConfirmationComponent from "./RecoveryConfirmationComponent";
import ShoppingComponent from "./ShoppingView/ShoppingComponent";
import DashBoardPurchaseComponent from "./DashBoardPurchase/DashBoardPurchaseComponent";
import { Purchase, PurchaseData } from "../app/shopping/entities/shopping";
import { Prices } from "../app/shopping/entities/prices";
import { messageSelector } from "../app/store/adapters/redux/actionMessage";
import ProfileComponent from "./Profile/ProfileComponent";
import InvalidUserComponent from "./InvalidUser/InvalidUserComponent";
import { Metrics } from "../app/metrics/entities";
import { metricsByIdSelector, metricsSelector } from "../app/store/adapters/redux/metrics";
import LeaderBoardComponent from "./LeaderBoard/LeaderBoardComponent";
import CalendarComponent from "./ScheduleRefactorize/CalendarComponent";
import './AppPresenter.css';

const { onUpdateSchedules, onCreateSchedules } = require('../graphql/subscriptionsOnUpdate');


export type UserSchedueleData = {
    id: number;
    table: string[];
    hours: string[];
    user: string;
};

export interface Props {
  user: User | null;
  loading: boolean;
  loadingSignOut: boolean;
  allSchedule: Schedule[] | null;
  schedule: UserSchedueleData[] | null;
  allUsers: User[] | null;
  allPurchase: Purchase [] | null;
  allPrices: Prices[] | null;
  actionMessage: String | null;
  allMetrics: Metrics[] | null;
  metricsById: Metrics[] | null;
  dispatchSignIn: (email: string, password: string) => void; // Adjusted dispatchSignIn signature
  stateCurrentUser: () => void;
  dispatchSignOut: () => void;
  dispatchSignUp: (
    username: string,
    password: string,
    email: string,
    phone_number: string,
    given_name: string,
    family_name: string
  ) => void;
  dispatchSendConfirmationCode: (email: string, code: string) => void,
  dispatchResendEmailCode: (email: string) => void,
  dispatchGetUserSchedule: (id: string) => void,
  dispatchLoading: (state: boolean) => void,
  dispatchGetAllSchedule: (date: Date) => void,
  dispatchUpdateUserSchedule: (data: UserSchedueleData[]) => void,
  dispatchUpdateOnSchedule: (data: any) => void,
  dispatchGetAllUsers: () => void,
  dispatchUpdateUserInfo:(id: string, active: boolean, activeDays: number, limitDate: string, userType: string, startDate: string) => void,
  dispatchUpdateLocalUserInfo:(
    id: string,
    active: boolean,
    activeDays: number,
    limitDate: Date,
    userType: number,
    startDate: Date,
  ) => void,
  dispatchSendRecoveryCode: (email: string) => void,
  dispatchSendPasswordRecovery: (email: string, code: string, password: string) => void,
  dispatchGetAllPurchase: () => void,
  dispatchCreatePurchase: (data: PurchaseData) => void,
  dispatchDeletePurchase: (id: string) => void,
  dispatchGetAllPrices: () => void,
  dispatchUpdatePrices: (
    id: string,
    monthly: string,
    valley: string,
    quarterly: string,
    semester: string,
    ticket01: string,
    ticket04: string,
    ticket08: string,
    ticket12: string,
    ticket16: string,
  ) => void,
  dispatchUpdateUserProfileInfo: (
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
  ) => void,
  dispatchGetAllMetricsInfo: () => void,
  dispatchGetMetricsById: (id: string) => void,
  dispatchUpdateUserMetrics: (
    id: string,
    deadlift: number,
    backsquat: number,
    frontsquat: number,
    cleanandjerk: number,
    snatch: number,
    shoulderpress: number,
    benchpress: number,
  ) => void
}

export const AppModel = (props: Props) => {    

    const location = useLocation();

    const [ errorMessage, setErrorMessage ] = React.useState("");
    const [ modal, setMoldal] = React.useState(false);

    const onSignIn = async (email: string, password: string) => {
        props.dispatchSignIn(email, password);
    };

    const onResendCode = (email: string) => {
        props.dispatchResendEmailCode(email);
    }

    const onSignOut = () => {
        props.dispatchSignOut();
    };

    const onSignUp = (
        username: string,
        password: string,
        email: string,
        phone_number: string,
        given_name: string,
        family_name: string) => {
        props.dispatchSignUp(
            username,
            password,
            email,
            '+57' + phone_number,
            given_name,
            family_name
        );
    };

    const onSendConfirmation = (email: string, code: string) => {
        props.dispatchSendConfirmationCode(email, code);
    };

    const onSendRecoveryCode = (email: string) => {
        props.dispatchSendRecoveryCode(email);
    }
    const onSendPasswordRecovery = (email: string, code: string, password: string) => {
        props.dispatchSendPasswordRecovery(email, code, password);
    }

    const onSaveUserSchedule = (data: UserSchedueleData[]) => {
        props.dispatchUpdateUserSchedule(data);
        //props.dispatchGetAllSchedule(new Date());
    };

    const onSaveUserProfileInfo = (id: string, firstName: string, lastName: string, email: string, phoneNumber: string) => {
        props.dispatchUpdateUserProfileInfo(id, firstName, lastName, email, phoneNumber);
    };

    const onSaveUserMetrics = (
        id: string,
        deadlift: number,
        backsquat: number,
        frontsquat: number,
        cleanandjerk: number,
        snatch: number,
        shoulderpress: number,
        benchpress: number,
    ) => {
        props.dispatchUpdateUserMetrics(
            id,
            deadlift,
            backsquat,
            frontsquat,
            cleanandjerk,
            snatch,
            shoulderpress,
            benchpress
        );
    }

    const setupSubscription = () => {
        const client = generateClient();
        const subscriptionUpdateSchedule = (client
            .graphql({
                query: onUpdateSchedules
            }) as any).subscribe({
                next: async (data: any) => {
                    props.dispatchUpdateOnSchedule(data.data.onUpdateSchedules);
                },
                error: (error: any) => console.warn(error)
            })
            
        const subscriptionCreateSchedule = (client
            .graphql({
                query: onCreateSchedules
            }) as any).subscribe({
                
                next: async (data: any) => {
                    props.dispatchUpdateOnSchedule(data.data.onCreateSchedules);
                },
                error: (error: any) => console.warn(error)
            });

            const subscriptionUpdateUser = (client
                .graphql({
                    query: onUpdateUser
                }) as any).subscribe({
                    next: async (data: any) => {
                        if (data.data.onUpdateUser.active !== null) {
                            handleUserUpdate(data);
                        }
                    },
                    error: (error: any) => console.warn(error)
                });

    };

    const handleUserUpdate = (data: any) => {
        
            props.dispatchUpdateLocalUserInfo(
                data.data.onUpdateUser.id,
                data.data.onUpdateUser.active,
                data.data.onUpdateUser.activeDays,
                data.data.onUpdateUser.limitDate,
                data.data.onUpdateUser.userType,
                data.data.onUpdateUser.startDate
            );
            
    }

    const handleUpdateUserInfo = (email: string, active: boolean, activeDays: number, limiteDate: string, userType: string, startDate: string) => { 
        props.dispatchUpdateUserInfo(email, active, activeDays, limiteDate, userType, startDate);
        if (location.pathname.includes("purchase")) {
            props.dispatchDeletePurchase(email);
        }
    }

    const handleUpdatePricesInfo = (
        id: string,
        monthly: string,
        valley: string,
        quarterly: string,
        semester: string,
        ticket01: string,
        ticket04: string,
        ticket08: string,
        ticket12: string,
        ticket16: string,
    ) => {
        props.dispatchUpdatePrices(
            id,
            monthly,
            valley,
            quarterly,
            semester,
            ticket01,
            ticket04,
            ticket08,
            ticket12,
            ticket16,
        )
        
    }

    const onSaveUserShopping = (activeDays: number, limitDate: string, startDate: string, planSelected: string) => {
        const data: PurchaseData = {
            id: props.user?._email,
            email: props.user?._email,
            activeDays: activeDays,
            limitDate: limitDate,
            startDate: startDate,
            firstName: props.user?._firstName,
            lastName: props.user?._lastName,
            plan: planSelected
        };
        props.dispatchCreatePurchase(data);
        
    }

    React.useEffect(() => {
        const fetchData = async () => {
            props.stateCurrentUser();
            props.dispatchGetAllSchedule(new Date());
            props.dispatchGetAllUsers();
            props.dispatchGetAllPurchase();
            props.dispatchGetAllPrices();
            props.dispatchGetAllMetricsInfo();
            if (props.user?._email) {
                props.dispatchGetMetricsById(props.user?._email);
            }
        };
        fetchData();
        setupSubscription();
    }, [props.user?._email]);

    React.useEffect(() => {
        const fetchData = async () => {
            if (props.user?._email) {
                props.dispatchGetMetricsById(props.user?._email);
            }
        };
        fetchData();
    }, [props.user?._email]);
    
    React.useEffect(() => {
        // console.log(props.allUsers);
        // let user: any = props?.allUsers?.filter((item: any) => item.email === 'ronycerquera@gmail.com');
        // console.log(JSON.parse(user[0].schedule));
        // JSON.parse(user[0].schedule).map((item: any) => {
        //     console.log(new Date(item.id), item.id);
            
        // });
        // const dateCounts: any = {};
  
        // props.allSchedule?.forEach((item: any) => {
        //   const date = item.date;
        //   dateCounts[date] = (dateCounts[date] || 0) + 1;
        // });
      
        // const repeatedDates = Object.keys(dateCounts).filter(date => dateCounts[date] > 1);
        
        // console.log(repeatedDates);
        // console.log(props.allUsers);
        // console.log(props.allSchedule);
        
        
        // props.allUsers?.map((item: any) => {
        //     //console.log(JSON.parse(item.schedule));
        //     JSON.parse(item.schedule).map((item2: any) => {
        //         //console.log(item2.hours);
        //         item2.hours.length > 0 && item2.hours.map((item3: any) => {
        //             //console.log(item3, item2.user, item2.id);
        //             let scheduleid = props.allSchedule?.filter((item4: any)=> {
        //                 if (item4.id.toString() === item2.id.toString()) {
        //                     return true;
        //                 }
        //             }) || [];
        //             if (scheduleid?.length > 0) {
        //                 let checkflag = {status: false, user: item2, key: ''};
        //                 for (const cluster of scheduleid) {
        //                     for (const [key, value] of Object.entries(cluster)) {
        //                         if (key.startsWith('cluster') && value.includes(item2.user)) {
        //                             checkflag = {status: true, key: key, user: item2}
        //                             return;
        //                         }
        //                     }
        //                 }
        //                 if (!checkflag.status) {
        //                     console.log(checkflag);
        //                 }
        //             }
                    
        //         })
                
        //     })
        //     //props.dispatchUpdateUserSchedule(JSON.parse(item.schedule));
        // })
        
        
        
    }, [props.user]);

    React.useEffect(() => {
        
        if (props.actionMessage !== null) {
            if (props.actionMessage.includes('Tu compra ha sido enviada')) {
                setMoldal(true);
            }
            
            if (!props.actionMessage.includes('scheduleUpdated')) {
                toast.success(props.actionMessage , {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                    });
            }

            if (props.actionMessage.includes('scheduleUpdated') && props.actionMessage.includes('500')) {
                toast.error('Hubo un error al tratar de reservar, intentalo de nuevo' , {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                    });
            }
            
        }
        
    }, [props.actionMessage?.length])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            // Check if any of the props are still null after 10 seconds
            if (!location.pathname.includes("signin") && (props.user === null || props.allSchedule === null || props.allUsers === null)) {
                setErrorMessage("Me demoro un poquito más 😳");
                const fetchData = async () => {
                    props.stateCurrentUser();
                    props.dispatchGetAllSchedule(new Date());
                    props.dispatchGetAllUsers();
                  };
                  fetchData();
                  setupSubscription();
            }
        }, 6000); //

        // Clear the timeout when the component unmounts or when props are populated
        return () => clearTimeout(timeout);
        
    }, [props.user, props.allSchedule, props.allUsers, props.allPurchase]); // Run effect whenever these props change

    React.useEffect(() => {
    }, [props.metricsById])
  
    if (props.user === null || props.allUsers === null || props.allSchedule === null) {
        return <LoadingComponent message={errorMessage}/>
    }

    return (
        <>
        <ToastContainer />
        {
        modal &&
            <div className="purchase_modal_success">
                <div className="purchase_modal_success_inner">
                    <p className="modal_title">
                    ¡Hemos recibido tu solicitud para tu nuevo plan!
                    </p>
                    <p className="modal_subtitle">
                    Recuerda consignar el valor de tu mensualidad en cualquiera de las siguientes opciones:
                    </p>
                    <div className="modal_options">
                        <p>1. Nequi 3185676840</p>
                        <p>2. Davivienda</p>
                        <p>3. O por efectivo</p>
                    </div>
                    <p className="modal_subtitle">
                    Y enviarnos el comprobante de pago vía Wpp: <span style={{fontStyle: 'italic', fontWeight: '500'}}>3185676840</span> para hacer la activación de tu plan. 
                    ¡Bienvenido a la familia!
                    </p>
                    <div className="modal_close" onClick={() => {
                        setMoldal(!modal);
                    }}>cerrar</div>
                </div>
            </div>
        }
        
        <Routes>
            <Route path='/' element={<CalendarComponent signOut={onSignOut} onSave={onSaveUserSchedule} props={props}  />} />
            <Route path='/user' element={<CalendarComponent signOut={onSignOut} onSave={onSaveUserSchedule} props={props}  />} />
            <Route path='/profile' element={<ProfileComponent signOut={onSignOut} props={props} onSave={onSaveUserProfileInfo} OnSaveMetrics={onSaveUserMetrics} />} />
            <Route path='/leaderboard' element={<LeaderBoardComponent signOut={onSignOut} props={props} onSave={onSaveUserProfileInfo} OnSaveMetrics={onSaveUserMetrics} />} />
            <Route path='/signin' element={<SignInComponent onClick={onSignIn} props={props} />} />
            <Route path='/signup' element={<SignUpComponent onClick={onSignUp} props={props}/>} />
            <Route path='/confirmation' element={<UserConfirmationComponent props={props} onClick={onSendConfirmation} onResendCode={onResendCode} />} />
            <Route path='/invalid' element={<InvalidUserComponent signOut={onSignOut} props={props}  />} />
            <Route path='/dashboard' element={<DashBoardComponent props={props} updateUser={handleUpdateUserInfo} />} />
            <Route path='/purchase' element={<DashBoardPurchaseComponent props={props} updateUser={handleUpdateUserInfo} updatePrices={handleUpdatePricesInfo} />} />
            <Route path='/recovery' element={<SendRecoveryConfirmationComponent props={props} onClick={onSendConfirmation} onResendCode={onSendRecoveryCode} />}/>
            <Route path='/reset' element={<RecoveryConfirmationComponent props={props} onClick={onSendConfirmation} onResendCode={onSendPasswordRecovery} />}/>
            <Route path="/shopping" element={<ShoppingComponent signOut={onSignOut} onSave={onSaveUserShopping} props={props} />}/>
        </Routes>
        </>
        
    );
};

const mapStateToProps = (state: StateType) => ({
  user: userSelector(state),
  schedule: userScheduleSelector(state),
  loading: loadingSelector(state),
  loadingSignOut: loadingSignOutSelector(state),
  allSchedule: allScheduleSelector(state),
  allUsers: allUsersSelector(state),
  allPurchase: allPurchaseSelector(state),
  allPrices: allPricesSelector(state),
  actionMessage: messageSelector(state),
  allMetrics: metricsSelector(state),
  metricsById: metricsByIdSelector(state),
});

const mapDispatchToProps = {
  dispatchSignIn: signInAction,
  dispatchSignUp: signUpAction,
  dispatchSendConfirmationCode: sendEmailConfirmationAction,
  stateCurrentUser: getCurrentUserAction,
  dispatchSignOut: signOutUserAction,
  dispatchResendEmailCode: resendEmailCodeAction,
  dispatchGetUserSchedule: getUserScheduleAction,
  dispatchGetAllSchedule: getAllScheduleAction,
  dispatchUpdateUserSchedule: updateUserSchedulesAction,
  dispatchUpdateOnSchedule: updateOnSchedulesAction,
  dispatchGetAllUsers: getAllUsersAction,
  dispatchUpdateUserInfo: updateUserInfoAction,
  dispatchUpdateLocalUserInfo: updateLocalUserInfoAction,
  dispatchSendRecoveryCode: sendRevoveryCodeAction,
  dispatchSendPasswordRecovery: sendNewPasswordRecoveryAction,
  dispatchGetAllPurchase: getAllPurchaseAction,
  dispatchCreatePurchase: createPurchaseAction,
  dispatchDeletePurchase: deletePurchaseAction,
  dispatchGetAllPrices: getAllPricesAction,
  dispatchUpdatePrices: updateAllPricesAction,
  dispatchUpdateUserProfileInfo: updateUserProfileInfoAction,
  dispatchGetAllMetricsInfo: getAllMetricsAction,
  dispatchGetMetricsById: getMetricsByIdAction,
  dispatchUpdateUserMetrics: updateUserMetricsAction
};

export default connect(mapStateToProps, mapDispatchToProps)(AppModel);
