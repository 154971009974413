import { Either } from "../../utils/CheckResponseSide";
import { User } from "../entities";

export interface SendConfirmationService {
  sendEmailConfirmationCode: (email: string, code: string) => Promise<Either<Error, User>> ;
}

export class SendConfirmationInteractor {
  sendCodeService: SendConfirmationService;

  constructor(sendCodeService: SendConfirmationService) {
    this.sendCodeService = sendCodeService;
  }

  async sendCode(email: string, code: string): Promise<Either<Error, User>> {
    const response = await this.sendCodeService.sendEmailConfirmationCode(email, code);
    return response;
  }
}