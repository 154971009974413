import { Either } from "../../utils/CheckResponseSide";
import { MetricsData } from "../entities/metrics";

export interface GetAllMetricsService {
  getAllMetricsImpl: () => Promise<Either<Error, MetricsData[]>> ;
}

export class GetAllMetricsInteractor {
  getAllMetricsService: GetAllMetricsService;

  constructor(getAllMetricsService: GetAllMetricsService) {
    this.getAllMetricsService = getAllMetricsService;
  }

  async getAllMetrics(): Promise<Either<Error, MetricsData[]>> {
    const response = await this.getAllMetricsService.getAllMetricsImpl();
    return response;
  }
}